import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  cancelSportsBettingHistoryV2,
  deleteSportsBettingHistoryV2,
  searchSportsBettingHistoryV2,
} from '../../api/game/sportsGameApi'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  SportMarketTypeV2,
  SportsResultSettlementTypeEnumsV2,
  SportsResultStatusEnums,
} from '../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import BettingHistoryRadio from './BettingHistoryRadio'

const SportsBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [subMenuTitle, setSubMenuTitle] = useState('PREMATCH')

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
    subMenuTitle,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      subMenuTitle,
      page: 0,
      size,
    }))
  }, [subMenuTitle])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    if (loading) return
    setLoading(true)
    await searchSportsBettingHistoryV2(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')

    // 취소 가능 시간
    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.sportsDetailBettingLogList.findIndex(
      log => moment(log.bettingCancelTimeCriteriaBeforeStart).tz('Asia/Seoul') < currentTime,
    )

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistoryV2({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
      cancelTime: currentTime,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfo())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'SPORTS-1043':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'SPORTS-1044':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistoryV2({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getBetBox = detail => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = detail.baseLine?.match(pattern)

    // 핸디일때
    if (detail.marketFamilyType === 11 && match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = detail.baseLine.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = detail.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (detail.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {detail.betNameKr} {detail.line && <>({finalLine.toFixed(1) || '-'})</>}
        </>
      )
    }

    return (
      <>
        {detail.betNameKr} {detail.line && <>({detail.lineNum.toFixed(1) || '-'})</>}
      </>
    )
  }

  return (
    <>
      <BettingHistoryTableWrap className="div_Bet_List" ref={sportsBettingHistoryWrapRef}>
        {loading && (
          <SportsGamePageLoadingOverlay>
            <CustomLoading isGameStart info={loadingInfo} />
          </SportsGamePageLoadingOverlay>
        )}
        <ListWrap>
          <SubMenuItemListWrap>
            <SubMenuItemBox
              active={subMenuTitle === 'PREMATCH'}
              onClick={() => setSubMenuTitle('PREMATCH')}
              className="normal_button"
            >
              프리매치
            </SubMenuItemBox>
            <SubMenuItemBox
              active={subMenuTitle === 'INPLAY'}
              onClick={() => setSubMenuTitle('INPLAY')}
              className="normal_button"
            >
              인플레이
            </SubMenuItemBox>
          </SubMenuItemListWrap>
          <div style={{ display: 'flex', gap: '5px', marginTop: '20px' }}>
            <a
              className="normal_button"
              style={{ height: '20px', width: '110px' }}
              onClick={() => {
                setCheckRadio(
                  sportsBettingHistoryContent
                    .filter(value => value.sportsResultStatus !== 'ING')
                    .map(value => value.sportsBettingLogKey),
                )
              }}
            >
              전체선택
            </a>
            <a style={{ height: '20px', width: '110px' }} className="normal_button" onClick={() => onDeleteHandler()}>
              삭제하기
            </a>
            {/* <CustomDeleteButton
              
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton> */}
          </div>
        </ListWrap>
        {sportsBettingHistoryContent.length === 0 ? (
          <NoContentDiv>
            <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
            스포츠 배팅내역이 없습니다.
            {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
          </NoContentDiv>
        ) : (
          <div className="bet_group" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {sportsBettingHistoryContent.map((item, index) => (
              <table style={{ textAlign: 'center' }} className="tb_bet_list">
                <colgroup>
                  <col style={{ width: '8%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '35%' }} />
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '5%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>경기일시</th>
                    <th>국가 - 리그</th>
                    <th>경기</th>
                    <th>타입</th>
                    <th>마켓</th>
                    <th>배팅</th>
                    <th>배당</th>
                    <th>결과</th>
                  </tr>
                </thead>
                <tbody>
                  {item.bonusFolderNum !== 0 && (
                    <tr>
                      <td>{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</td>
                      <td>-</td>
                      <td>[{item.bonusFolderNum}폴더 이상]</td>
                      <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                        <BettingHistoryContentItemBox width="100" sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]}
                        </BettingHistoryContentItemBox>
                      </td>

                      <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>-</td>
                      <td style={{ color: '#fff', fontWeight: '600', background: 'linear-gradient(#000, #000)' }}>
                        <SportsEventTitleTypeImg
                          src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                          style={{ width: '18px', height: '18px' }}
                          alt=""
                        />
                        &nbsp;보너스 배당
                      </td>
                      <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                        {item.bonusFolderPer}
                      </td>
                      <td>
                        <BettingHistoryContentBoxFooterInfo bettingResult="WINNER">
                          적중
                        </BettingHistoryContentBoxFooterInfo>
                      </td>
                    </tr>
                  )}
                  {item.sportsDetailBettingLogList.map((detail, detailIndex, array) => (
                    <tr key={detail.sportsDetailBettingLogKey}>
                      <td>{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</td>

                      <td>
                        <img
                          style={{ width: '18px', height: '18px' }}
                          src={`${process.env.PUBLIC_URL}/img/sports/sportsIdType/${detail.sportId}.png`}
                          alt=""
                        />
                        &nbsp;{detail.locationName || '-'} - {detail.leagueName || '-'}
                      </td>
                      <td>
                        {detail.homeName} VS {detail.awayName}
                      </td>
                      <td style={{ background: 'linear-gradient(#fff, #f5fcff)' }}>
                        <BettingHistoryContentItemBox width="100" sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]}
                        </BettingHistoryContentItemBox>
                      </td>
                      <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                        {detail.marketName}
                      </td>
                      <td
                        style={{
                          color: '#fff',
                          fontWeight: '600',
                          background: 'linear-gradient(#000, #000)',
                          borderBottom: detailIndex === array.length - 1 ? 'none' : 'solid 1px #fff',
                        }}
                      >
                        {getBetBox(detail)}
                      </td>
                      <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                        {detail.priceNum || '-'}
                      </td>
                      <td>
                        <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
                          {SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}
                        </BettingHistoryContentBoxFooterInfo>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={8} className="td_result_line" style={{ width: '100%' }}>
                      <BettingHistoryContentBox>
                        {item.sportsResultStatus === 'ING' ? (
                          <>
                            {item.allowBettingCancel && (
                              <BettingCancelBox>
                                <a
                                  className="btBet_list_Button"
                                  style={{ width: '66px', color: 'red' }}
                                  onClick={() => {
                                    onClickBettingHistoryCancelHandler(item)
                                  }}
                                >
                                  배팅 취소
                                </a>
                              </BettingCancelBox>
                            )}
                          </>
                        ) : (
                          <>
                            <BettingHistoryRadio
                              sportsBettingLogKey={item.sportsBettingLogKey}
                              sportsResultStatus={item.sportsResultStatus}
                              checkRadio={checkRadio}
                              setCheckRadio={setCheckRadio}
                            />
                          </>
                        )}

                        <BettingHistoryContentBoxFooterInfo>
                          <span>배팅 시간 :</span> {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                        </BettingHistoryContentBoxFooterInfo>
                        <BettingHistoryContentBoxFooterInfo>
                          <span>배팅 금액 : </span>
                          {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                        </BettingHistoryContentBoxFooterInfo>
                        <BettingHistoryContentBoxFooterInfo>
                          <span>배당 : </span>
                          {(() => {
                            const {
                              realReduceBettingPerForSingleFolder,
                              realReduceBettingPerForCombination,
                              totalOddValue,
                              originTotalOddValue,
                            } = item
                            const reduceBettingValue =
                              realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                            if (reduceBettingValue === null) {
                              return <span>{totalOddValue}</span>
                            }

                            return (
                              <>
                                {Number(reduceBettingValue) < 0 ? (
                                  <span>
                                    ({totalOddValue} / {originTotalOddValue} {reduceBettingValue})
                                  </span>
                                ) : (
                                  <span>
                                    ({totalOddValue} / {originTotalOddValue} + {reduceBettingValue})
                                  </span>
                                )}
                              </>
                            )
                          })()}
                        </BettingHistoryContentBoxFooterInfo>
                        <BettingHistoryContentBoxFooterInfo>
                          <span>예상 적중금액 : </span>
                          {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                        </BettingHistoryContentBoxFooterInfo>
                        <BettingHistoryContentBoxFooterInfo>
                          <span>적중 금액 : </span>
                          {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                        </BettingHistoryContentBoxFooterInfo>
                        <BettingHistoryContentBoxFooterInfo2 bettingResult={item.sportsResultStatus}>
                          <span>결과 : </span>
                          {SportsResultStatusEnums[item.sportsResultStatus]}
                        </BettingHistoryContentBoxFooterInfo2>
                      </BettingHistoryContentBox>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        )}
      </BettingHistoryTableWrap>
      {totalElements > 0 && (
        <>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
    </>
  )
}

export default SportsBettingHistoryV2

const SubMenuItemListWrap = styled.div`
  // border-bottom: 1px solid rgb(41, 41, 41, 0.2);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  height: 75px;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  border-radius: 24px;
  &:hover {
    border: solid 1px #000;
  }

  ${props =>
    props.active &&
    `
    border: solid 1px #000 !important;
    font-weight: 500 !important;
    `}
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: -20px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: -20px;
  margin-bottom: 20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #000;
    cursor: pointer;
  }
  th {
    height: 35px;
    padding: 4px 4px;
    &:last-child {
      border-right: 0;
    }
    border-collapse: separate !important;
    border-spacing: 1px !important;
    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead`
  border-collapse: separate !important;
  border-spacing: 1px !important;
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return '#000'
  }
}

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 500;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  font-weight: 500;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px #525252;
  // border-right: solid 1px #525252;
  // border-bottom: solid 1px #525252;

  font-weight: 700;
  th {
    border: solid 1px rgb(21, 21, 21);
    border-collapse: separate !important;
    border-spacing: 1px !important;
  }
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#c7c700'
    case 'SPECIAL':
      return '#27b802'
    case 'OVERSEAS':
      return '#b402bd'
    case 'LIVE':
      return '#cc6c00'
    default:
      return '#000'
  }
}

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  // background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  color: ${props => getColor3(props.sportMarketType)};

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  // &:not(:last-child) {
  //   border-right: 1px solid #525252;
  // }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    color: #000;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  // width: 100%;
`

const BettingCancelButton = styled.button`
  width: 10%;
  position: relative;
  color: red;
  margin-top: 2px;
  margin-left: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`
