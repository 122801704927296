import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSearchParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { searchRecommendCode, siginIn } from '../../api/authManage/authManageApi'
import { ButtonH40 } from '../../shared/components/GoldButton'
import MobileSignUpPopup from '../MobileLayout/MobileSignUp'
import { HermesMainBorder, HermesMainHover2, KyochonMainText } from '../../utils/palette'
import { createAccessToken, handleSignIn } from '../../socketio/userAuthSocketIOHandler'
import { setUserInfo } from '../../redux/memberInfoSlice'
import { setSportsBettingInfos } from '../../redux/sportsBettingSlice'
import { setAccessToken } from '../../utils/token'
import CustomLoading from '../../containers/CustomLoading'
import { confirmCode } from '../../redux/authSlice'

const MobileLoginPage = () => {
  const dispatch = useDispatch()
  const [recParams] = useSearchParams()
  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')
  const navigate = useNavigate()

  // const [inputCaptchaCode, setInputCaptchaCode] = useState('')

  // useEffect(() => {
  //   loadCaptchaEnginge(4, 'black', 'white', 'numbers')
  // }, [])

  const [siginInFlag, setSignInFlag] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const resetData = () => {
    setMemberId('')
    setMemberPassword('')
    // setInputCaptchaCode('')
    // loadCaptchaEnginge(4, 'black', 'white', 'numbers')
  }

  const onSubmit = e => {
    if (!memberId) {
      alert('아이디를 입력하세요.')
      resetData()
      return
    }
    if (!memberPassword) {
      alert('비밀번호를 입력하세요.')
      resetData()
      return
    }

    if (siginInFlag) {
      return
    }

    setSignInFlag(true)

    const body = {
      userId: memberId,
      password: memberPassword,
      // eslint-disable-next-line no-useless-escape
      host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
    }

    handleSignIn(body)
      .then(signInRes => {
        if (!signInRes.status) {
          alert('아이디, 비밀번호 확인 후 다시 시도해주세요.')
          setSignInFlag(false)
          return
        }
        const tokenData = {
          userId: memberId,
          password: memberPassword,
        }

        // 토큰 발급
        createAccessToken(tokenData)
          .then(atRes => {
            if (!atRes.status) {
              alert('다시 로그인해주세요.')
              setSignInFlag(false)
              return
            }
            // 유저 정보 리덕스에 저장
            dispatch(setUserInfo(atRes.data.user))

            dispatch(setSportsBettingInfos(atRes.data.user.sportsBettingInfos))

            // 로컬 스토리지에 토큰 저장
            setAccessToken(atRes.data.accessToken)
            setSignInFlag(false)
            const noteSize = atRes.data.user.noteRedisSize
            const oneToOneSize = atRes.data.user.oneToOneRedisSize
            if (noteSize !== 0 && oneToOneSize === 0) {
              // alert('쪽지함을 먼저 확인해주세요.')
              navigate('/mobile/note')
            } else if (noteSize !== 0 && oneToOneSize !== 0) {
              // alert('쪽지함을 먼저 확인해주세요.')
              navigate('/mobile/note')
            } else if (noteSize === 0 && oneToOneSize !== 0) {
              // alert('1:1문의 답변을 먼저 확인해주세요.')
              navigate('/mobile/onetoone')
            } else {
              navigate('/mobile/main')
            }
          })
          .finally(() => {
            setSignInFlag(false)
            resetData()
          })
      })
      .finally(() => {
        setSignInFlag(false)
        resetData()
      })
  }

  const [showCodePage, setShowCodePage] = useState(false)
  const [recommendCode, setRecommendCode] = useState()

  useEffect(() => {
    searchRecommendCode().then(res => {
      const rec = res.data.recommendCode
      if (rec) {
        setRecommendCode(rec)
      }
    })
  }, [])

  const checkRecommendCode = e => {
    if (!recommendCode) {
      alert('가입코드를 입력하세요.')
      return
    }

    if (siginInFlag) {
      return
    }
    setSignInFlag(true)

    dispatch(confirmCode(recommendCode))
      .unwrap()
      .then(() => {
        setSignInFlag(false)
        navigate(`/mobile/signup?recommendCode=${encodeURIComponent(recommendCode)}`)
      })
      .catch(() => {
        setSignInFlag(false)
        setRecommendCode()
        alert('유효하지 않는 가입코드입니다.')
      })
  }

  useEffect(() => {
    const originalBackground = document.body.style.background

    document.body.style.background = 'black'

    return () => {
      document.body.style.background = originalBackground
    }
  }, [])

  return (
    <>
      {showCodePage ? (
        <div style={{ background: '#000' }}>
          <div style={{ height: '60px' }} />
          <div style={{ height: '3px' }} />
          <div style={{ textAlign: 'center' }}>
            <div id="logo2">
              <img
                align="middle"
                src={`${process.env.PUBLIC_URL}/img/logo/logo.png`}
                style={{ width: '240px' }}
                alt=""
              />
            </div>
          </div>
          <div style={{ height: '40px' }} />
          <div style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              <input
                name="IC_Amount"
                type="text"
                id="IC_Amount"
                maxLength="16"
                value={recommendCode}
                placeholder="가입코드"
                style={{
                  backgroundPosition: 'left',
                  width: '300px',
                  borderRadius: '0px',
                  height: '34px',
                  color: '#555',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  backgroundColor: '#ffffff',
                  border: '#ddd 1px solid',
                }}
                onChange={e => {
                  setRecommendCode(e.target.value)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    checkRecommendCode()
                  }
                }}
              />
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ textAlign: 'center' }}>
              <input
                type="button"
                name="btJoin"
                value="계속하기"
                id="btJoin"
                className="login01"
                style={{ color: '#fff' }}
                onClick={() => {
                  checkRecommendCode()
                }}
              />
            </div>
            <div style={{ height: '10px' }} />
            <div style={{ textAlign: 'center' }}>
              <input
                type="button"
                className="login02"
                value="뒤로가기"
                style={{ color: '#000' }}
                onClick={() => {
                  setShowCodePage(false)
                }}
              />
            </div>
            <div style={{ height: '25px' }} />
          </div>
        </div>
      ) : (
        <LoginPageWrap>
          {siginInFlag && <CustomLoading />}
          {signUpModalShow && (
            <MobileSignUpPopup visible={signUpModalShow} onClose={onCloseSignUpModal} rec={recParams.get('rec')} />
          )}
          <div style={{ height: '30px' }} />
          <div>
            <div>
              <div id="login_wrap">
                <div id="logo2">
                  <img
                    align="middle"
                    src={`${process.env.PUBLIC_URL}/img/logo/logo.png`}
                    style={{ width: '240px' }}
                    alt=""
                  />
                </div>

                <div id="loginbox">
                  <div className="formtextinput" style={{ position: 'relative', display: 'flex' }}>
                    <input
                      name="IC_Amount"
                      type="text"
                      id="IC_Amount"
                      value={memberId}
                      style={{ touchAction: 'none', paddingLeft: '30px', width: '100%' }}
                      className="account_input"
                      placeholder="Account"
                      onChange={e => {
                        setMemberId(e.target.value)
                      }}
                    />
                  </div>
                  <div style={{ height: '10px' }} />
                  <div className="formtextinput" style={{ position: 'relative', display: 'flex' }}>
                    <input
                      name="IC_Amount2"
                      type="password"
                      id="IC_Amount2"
                      style={{ touchAction: 'none', paddingLeft: '30px' }}
                      className="passwd_input"
                      value={memberPassword}
                      placeholder="Password"
                      onChange={e => {
                        setMemberPassword(e.target.value)
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          onSubmit()
                        }
                      }}
                    />
                  </div>

                  <div className="loginBtn" style={{ margin: '20px 0 0 0' }}>
                    <input
                      type="submit"
                      name="btLogin"
                      value="로그인"
                      id="btLogin"
                      className="bt_login"
                      onClick={onSubmit}
                    />
                  </div>
                  <div className="loginBtn" style={{ margin: '10px 0 0 0' }}>
                    <input
                      type="button"
                      name="btJoin"
                      value="회원가입"
                      id="btJoin"
                      className="bt_join"
                      onClick={() => {
                        setShowCodePage(true)
                      }}
                    />
                  </div>
                  <div style={{ height: '20px' }} />
                  <div
                    style={{ fontSize: '12px', marginTop: '10px', color: '#000' }}
                    // onClick={() => {
                    //   window.open('https://t.me/tysonsol')
                    // }}
                  >
                    ZEUS SPORTS
                    <br />
                    Copyright(c) 2019 All rights
                    <br />
                    reserved by ZEUS스포츠
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoginPageWrap>
      )}
    </>
  )
}

export default MobileLoginPage

const LoginPageWrap = styled.div`
  // margin: 0;
  // padding: 0;
  background: #000;
`

const LoginPageContent = styled.div`
  background: rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 480px;
`

const SignInModalHeader = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SignInModalHeaderLogo = styled.img`
  width: 250px;
  height: 150px;
`

// modal body content
const SignInModalContentWrap = styled.div`
  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 200px);
  width: 100%;

  padding: 0 20px;
`

// 이용 권한이 없습니다.
const SignInModalContentInfo1 = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;

  /* 엘로우폰트 */

  color: red;
`

// 아직 계정이 없으신가요?
const SignInModalContentInfo2 = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;

  color: #d3d3d3;
`

// form
const SignInModalFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  //   height: 100%;
`

// input Wrap
const SignInModalInputWrap = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 10px;
  gap: 10px;

  height: 42px;

  /* 회색테두리 */

  border: 1px solid #7b808c;
`

// input icon
const SignInModalInputIcon = styled.img`
  width: 20px;
  height: 20px;
`

// input line
const SignInModalInputLine = styled.div`
  width: 1px;
  height: 22px;

  /* 회색테두리 */

  background: #4a4a4a;
`

// input
const SignInModalInput = styled.input`
  width: 100%;
  // height: 20px;

  padding: 0 10px;

  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #fff;

  border: transparent;
  background: rgba(17, 19, 20, 0.7);
  height: 100%;

  outline: none;
  &::placeholder {
    color: #8c8b8b;
  }
`

// 버튼 안에 텍스트
const SignInModalButtonInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-align: center;

  color: #fff;
`

// 로그인 버튼
const SignInModalButton = styled.button`
  width: 100%;
  height: 40px;
  background: ${HermesMainHover2};
  border: none; /* Remove the border */
  border-radius: 5px;
`

// 회원 가입
const SignUpButtonInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-align: center;

  color: #fff;
`

const SignUpButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: solid 1px ${HermesMainBorder};
  background: none;
`
