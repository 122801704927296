import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import NoteAlert from '../../containers/NoteAlert'
import OneToOneAlert from '../../containers/OneToOneAlert'
import {
  createUserLogAction,
  fetchMemberInfo,
  initHomeInfoAction,
  initPopupInfoAction,
  initSportsInfoAction,
  initVirtualInfoAction,
  reAuthenticationAction,
  setNoteMessageInfo,
} from '../../redux/memberInfoSlice'
import { onClickTitleHandler } from '../../redux/modalSlice'
import useAudio from '../../utils/audioHook'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken, deleteAccessToken } from '../../utils/token'
import MobileFooter from './MobileFooter'
import MobileHeader from './MobileHeader'
import CustomLoading from '../../containers/CustomLoading'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import MobileFooterMenu from './MobileFooterMenu'

const MobileLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [pathName, setPathName] = useState('')

  const [noteAudioSrc, setNoteAudioSrc] = useState(`${process.env.PUBLIC_URL}/audio/noteAudio.mp3`)
  const [oneToOneAudioSrc, setOneToOneAudioSrc] = useState(`${process.env.PUBLIC_URL}/audio/oneToOneAudio.mp3`)

  const [playNoteAudio, pauseNoteAudio, pausedNoteAudio, noteAudioRef] = useAudio(noteAudioSrc)
  const [playOneToOneAudio, pauseOneToOneAudio, pausedOneToOneAudio, oneToOneAudioRef] = useAudio(oneToOneAudioSrc)

  const {
    holdingMoney,
    pointAmount,
    totalGameMoney,
    noteRedisSize,
    noteMessageInfo,
    oneToOneRedisSize,
    homeInitLoading,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      pointAmount: memberInfo.memberHoldingPoint,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      noteMessageInfo: memberInfo.noteMessageInfo,
      homeInitLoading: memberInfo.homeInitLoading,
    }
  })

  useEffect(() => {
    dispatch(initHomeInfoAction())
    dispatch(initPopupInfoAction())
    dispatch(initSportsInfoAction())
    dispatch(initVirtualInfoAction())

    if (isLogin()) {
      dispatch(reAuthenticationAction({ newWindow: false }))
    }
  }, [])

  useEffect(() => {
    setPathName(location.pathname)

    if (isLogin()) {
      const userTokenInfo = decodeAccessToken()

      dispatch(
        createUserLogAction({
          userId: userTokenInfo.userId,
          userName: userTokenInfo.userName,
          position: location.pathname,
          devicePixelRatio: window.devicePixelRatio,
          userAgent: window.navigator.userAgent,
          resolution: `${window.screen.width}X${window.screen.height}`,
          windowSize: `${window.innerWidth}X${window.innerHeight}`,
          platform: window.navigator?.platform || '',
        }),
      )
    }
  }, [location])

  // 쪽지 모달이 켜져 있으면 false
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (noteRedisSize > 0 && pathName !== '/mobile/note') {
      playNoteAudio()
      alert('쪽지함을 먼저 확인해주세요.')
      navigate('/mobile/note')
      return
    }
    if (noteRedisSize > 0 && pausedNoteAudio() && (pathName !== '/mobile/note' || pathName !== '/mobile/onetoone')) {
      playNoteAudio()
      return
    }

    if (noteRedisSize === 0 && oneToOneRedisSize > 0 && pathName !== '/mobile/onetoone') {
      playOneToOneAudio()
      alert('1:1문의 답변을 먼저 확인해주세요.')
      navigate('/mobile/onetoone')
      return
    }

    if (
      noteRedisSize === 0 &&
      oneToOneRedisSize > 0 &&
      pausedOneToOneAudio() &&
      (pathName !== '/mobile/note' || pathName !== '/mobile/onetoone')
    ) {
      playOneToOneAudio()
    }

    if (noteRedisSize > 0 && oneToOneRedisSize > 0 && pathName !== '/mobile/note') {
      playNoteAudio()
    }
  }, [oneToOneRedisSize, noteRedisSize, pathName])

  const onClickCloseHandler = () => {
    dispatch(setNoteMessageInfo(''))
    dispatch(onClickTitleHandler('NOTE'))
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)
  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) return
    if (totalGameMoney <= 0) return
    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setMoneyExchangeLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('처리 실패했습니다.')
        }
        setMoneyExchangeLoading(false)
      })
  }
  return (
    <div style={{ overflowY: 'hidden', paddingBottom: '110px' }}>
      {/* <div style={{ height: '5px' }} /> */}
      {homeInitLoading && <CustomLoading />}
      {/* {isValidPath && noteRedisSize > 0 && <NoteAlert noteSize={noteRedisSize} />}
      {isValidPath && noteRedisSize === 0 && oneToOneRedisSize > 0 && <OneToOneAlert noteSize={oneToOneRedisSize} />} */}
      <MobileHeader />
      <div style={{ position: 'relative', top: '58px' }}>
        <div className="div_user_Info" style={{ display: 'flex', flexDirection: 'column', color: '#0062df' }}>
          <div>
            보유머니 : <b id="user_money">{holdingMoney?.toString().replace(commonReg2, ',')}</b> 원{' '}
            <span style={{ color: '#ddd' }}>│</span> <span style={{ color: '#0062df' }}>포인트 : </span>
            <b id="user_point" style={{ color: '#0062df' }}>
              {pointAmount?.toString().replace(commonReg2, ',')}
            </b>{' '}
            P
            <a style={{ display: 'inline-block', margin: '0 10px' }} href="/mobile/money/pointexchange">
              <span style={{ padding: '2px 8px', color: '#2a2a2a', border: '1px solid #ddd', borderRadius: '4px' }}>
                전환
              </span>{' '}
            </a>
          </div>
          <div>
            카지노머니 : <b id="user_money">{totalGameMoney?.toString().replace(commonReg2, ',')}</b> 원{' '}
            <a
              style={{ display: 'inline-block', margin: '0 10px' }}
              onClick={onClickCasinoMoneyWithdrawHandler}
              //  href="exchange_point.aspx"
            >
              <span style={{ padding: '2px 8px', color: '#2a2a2a', border: '1px solid #ddd', borderRadius: '4px' }}>
                회수
              </span>{' '}
            </a>
          </div>
        </div>
        <Outlet />
        <div style={{ height: '10px' }} />
        <MobileFooter />
        <div style={{ height: '10px' }} />
      </div>
      {location.pathname === '/mobile/main' && <MobileFooterMenu />}
    </div>
    // <ContainerWrap>

    //   <ContainerSecondWrap>
    //     <MobileContainer>
    //       <Outlet />
    //     </MobileContainer>
    //     <MobileFooter />
    //   </ContainerSecondWrap>
    //   {/* <MobileScrollButton /> */}
    //   {/* <LiveChat /> */}
    // </ContainerWrap>
  )
}

export default MobileLayout

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
`

const ContainerWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const MobileContainer = styled.div`
  padding-top: 110px;
  margin-bottom: -10px;
  overflow: auto;
  // margin: 0 auto;
`
