import React, { Fragment } from 'react'
import { FaLock } from 'react-icons/fa'
import styled from 'styled-components'
import { HermesMainSelect, HermesMainText } from '../../utils/palette'

const createBettingCartItem = (fixture, market, selectedBetInfo) => {
  // betLines 를 제거하기 위함
  const { betLines, ...rest } = market

  return {
    ...fixture,
    ...rest,
    selectedBetInfo,
  }
}

const getDoBetTypeEmpty = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <SportsEventContentItem>
      <SportsEventContentItemMarketName> - </SportsEventContentItemMarketName>
      <FixtureMarketBetLineBox>
        <FixtureMarketBetBoxWrap>
          <FixtureMarketBetBox
            onClick={() => {
              onClickAddBettingCartHandler()
            }}
          >
            <FixtureMarketBetBoxName type="home-box">{fixture.homeName}</FixtureMarketBetBoxName>
          </FixtureMarketBetBox>
          <SportsEventContentItemPoint
            onClick={() => {
              onClickAddBettingCartHandler()
            }}
          >
            VS
          </SportsEventContentItemPoint>
          <FixtureMarketBetBox
            onClick={() => {
              onClickAddBettingCartHandler()
            }}
          >
            <FixtureMarketBetBoxName type="away-box">{fixture.awayName}</FixtureMarketBetBoxName>
          </FixtureMarketBetBox>
        </FixtureMarketBetBoxWrap>
      </FixtureMarketBetLineBox>
    </SportsEventContentItem>
  )
}

/**
 * 국내형
 */

// type 0
const getDoBetType0 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 1 - 기준점 있음
const getDoBetType1 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>
                          {bet.betNameKr} ( {bet?.lineNum?.toFixed(1) ?? bet?.line} )
                        </span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 2 - 승무패
const getDoBetType2 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'X') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '20%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                {drawData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{drawData?.priceNum?.toFixed(2) || 'VS'}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 3 - 승무패 - 기준점 있음
const getDoBetType3 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'X') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} ) {homeData.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '20%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                {drawData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} ) {drawData?.priceNum?.toFixed(2) || 'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData?.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 4 - 승패
const getDoBetType4 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>
                  {market.marketId === 99944 ? (
                    <>
                      {market.marketName} <br /> [예/아니오]
                    </>
                  ) : (
                    <>{market.marketName}</>
                  )}
                </b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>VS</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 5 - 승패 기준점 있음
const getDoBetType5 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} ) {homeData.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData?.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 6 - 언더오버
const getDoBetType6 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'OVER') acc.homeData = bet
            if (bet.betName === 'UNDER') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
                    alt=""
                    style={{ width: '12px', height: '12px', position: 'absolute', left: '5px' }}
                  />
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
                    alt=""
                    style={{ width: '12px', height: '12px', position: 'absolute', right: '5px' }}
                  />
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 7 - 정확한 언더오버
const getDoBetType7 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'OVER') acc.homeData = bet
            if (bet.betName === 'EXACTLY') acc.drawData = bet
            if (bet.betName === 'UNDER') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
                    alt=""
                    style={{ width: '12px', height: '12px', position: 'absolute', left: '5px' }}
                  />
                  <b>
                    ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} ) {homeData.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <b>
                    ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} ) {drawData.priceNum?.toFixed(2) || 0}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData?.priceNum?.toFixed(2) || 0}
                  </b>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
                    alt=""
                    style={{ width: '12px', height: '12px', position: 'absolute', right: '5px' }}
                  />
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 8 - 홀짝
const getDoBetType8 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'ODD') acc.homeData = bet
            if (bet.betName === 'EVEN') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData?.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>VS</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>{awayData?.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 9 - YES - NO
const getDoBetType9 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'YES') acc.homeData = bet
            if (bet.betName === 'NO') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData?.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>VS</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>{awayData?.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 10- YES - NO 기준점 있는거
const getDoBetType10 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'YES') acc.homeData = bet
            if (bet.betName === 'NO') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData?.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData?.betNameKr}
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 11 - 핸디
const getDoBetType11 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        const baseLineScore = homeData?.baseLineScore ?? awayData?.baseLineScore ?? '0-0'

        // 정규식 패턴: "0-0" 형태 검사
        const pattern = /^(\d+)-(\d+)$/
        const match = baseLineScore.match(pattern)

        const homeScore = Number.isNaN(Number(match[1])) ? 0 : Number(match[1])
        const awayScore = Number.isNaN(Number(match[2])) ? 0 : Number(match[2])

        const baseLineNum = homeData?.baseLineNum ?? awayData?.baseLineNum ?? 0
        // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
        const finalLine = baseLineNum - homeScore + awayScore

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>{finalLine?.toFixed(1) ?? 'VS'}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 12 - 유럽 핸디
const getDoBetType12 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'X') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {drawData.betNameKr} ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} )
                  </span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {awayData.betNameKr} ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} )
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 13 - Team to Score Ex) 첫 득점 팀
const getDoBetType13 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NO GOAL') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 14 - Next Gaol 류
const getDoBetType14 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NO GOAL') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {drawData.betNameKr} ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} )
                  </span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {awayData.betNameKr} ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} )
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 15 - WinningMarginNew 기준점 있음
const getDoBetType15 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>
                          {bet.betNameKr} ( {bet?.lineNum?.toFixed(1) ?? bet?.line} )
                        </span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 16 - Race To 류
const getDoBetType16 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NEITHER') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {drawData.betNameKr} ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} )
                  </span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {awayData.betNameKr} ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} )
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 17 - Every Period 3way
const getDoBetType17 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NEITHER') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 18 - Most 3-Way
const getDoBetType18 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'DRAW') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 19 - Next Corner 기준점 있음
const getDoBetType19 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NO CORNER') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {drawData.betNameKr} ( {drawData?.lineNum?.toFixed(1) ?? drawData?.line} )
                  </span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {awayData.betNameKr} ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} )
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 20 - First/Last Corner
const getDoBetType20 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NO CORNER') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 21 - 첫 카드
const getDoBetType21 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1') acc.homeData = bet
            if (bet.betName === 'NO CARD') acc.drawData = bet
            if (bet.betName === '2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>{drawData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 22 - Home Win No Bet
const getDoBetType22 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 23 - Goal/No Goal
const getDoBetType23 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'GOAL') acc.homeData = bet
            if (bet.betName === 'NO GOAL') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData?.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>VS</b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>{awayData?.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 24 - 첫 코너 시점
const getDoBetType24 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === 'NOT BEFORE') acc.homeData = bet
            if (bet.betName === 'BEFORE') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData?.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {' '}
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData?.betNameKr}
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 25 - Winning Conference - East / West
const getDoBetType25 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 26 - 하프타임/풀타임
const getDoBetType26 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        let size = 1

        if (betLine.bets.length % 3 === 0) {
          size = 3
        } else if (betLine.bets.length % 2 === 0) {
          size = 2
        }

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <>
                      {betList.map(bet => {
                        return (
                          <li
                            className={bet.isActive ? 't-name-on' : 't-name'}
                            style={{ width: '26%' }}
                            onClick={() => {
                              if (bet?.betStatusId !== 1) return

                              onClickAddBettingCartHandler(
                                createBettingCartItem(
                                  fixture,
                                  {
                                    ...market,
                                    bets: betLine.bets,
                                  },
                                  bet,
                                ),
                              )
                            }}
                          >
                            {bet?.betStatusId !== 1 && (
                              <SportsEventContentItemOverlay>
                                <SportsEventContentItemLockIcon />
                              </SportsEventContentItemOverlay>
                            )}
                            <div className="odds">
                              <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                              <b>{bet.priceNum?.toFixed(2) || 0}</b>
                            </div>
                          </li>
                        )
                      })}
                    </>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 27 - 더블찬스
const getDoBetType27 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const { homeData, drawData, awayData } = betLine?.bets?.reduce(
          (acc, bet) => {
            if (bet.betName === '1X') acc.homeData = bet
            if (bet.betName === '12') acc.drawData = bet
            if (bet.betName === 'X2') acc.awayData = bet
            return acc
          },
          { homeData: {}, drawData: {}, awayData: {} },
        ) || { homeData: {}, drawData: {}, awayData: {} }

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li
                className={drawData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (drawData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      drawData,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {drawData?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{drawData.betNameKr}</span>
                  <b>
                    <b>{drawData?.priceNum?.toFixed(2) || 0}</b>
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type28 - 정확한 스코어
const getDoBetType28 = (fixture, market, onClickAddBettingCartHandler) => {
  let firstColumns
  let secondColumns
  let thirdColumns

  if (market?.betLines.length === 2) {
    firstColumns = market?.betLines[0]?.bets || [] // 첫번째 열
    secondColumns = [] // 두번째 열
    thirdColumns = market?.betLines[1]?.bets || [] // 세번째 열
  } else {
    firstColumns = market?.betLines[0]?.bets || [] // 첫번째 열
    secondColumns = market?.betLines[1]?.bets || [] // 두번째 열
    thirdColumns = market?.betLines[2]?.bets || [] // 세번째 열
  }

  // 모든 열 중에서 가장 긴 길이를 찾습니다.
  const maxLength = Math.max(firstColumns.length, secondColumns.length, thirdColumns.length)

  return (
    <>
      {Array.from({ length: maxLength }).map((_, rowIndex) => {
        const firstColumn = firstColumns[rowIndex] || {}
        const secondColumn = secondColumns[rowIndex] || {}
        const thirdColumn = thirdColumns[rowIndex] || {}

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={firstColumn.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (firstColumn?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: [...firstColumns, ...secondColumns, ...thirdColumns],
                      },
                      firstColumn,
                    ),
                  )
                }}
              >
                {Object.keys(firstColumn).length !== 0 && firstColumn?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{firstColumn?.betNameKr || ''}</span>
                  <b>{firstColumn.priceNum?.toFixed(2) || ''}</b>
                </div>
              </li>
              <li
                className={secondColumn.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (secondColumn?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: [...firstColumns, ...secondColumns, ...thirdColumns],
                      },
                      secondColumn,
                    ),
                  )
                }}
              >
                <div className="odds">
                  {Object.keys(secondColumn).length !== 0 && secondColumn?.betStatusId !== 1 && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  <span style={{ float: 'left', marginLeft: '3px' }}>{secondColumn?.betNameKr || ''}</span>
                  <b>{secondColumn.priceNum?.toFixed(2) || ''}</b>
                </div>
              </li>
              <li
                className={thirdColumn.isActive ? 't-name-on' : 't-name'}
                style={{ width: '26%' }}
                onClick={() => {
                  if (thirdColumn?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: [...firstColumns, ...secondColumns, ...thirdColumns],
                      },
                      thirdColumn,
                    ),
                  )
                }}
              >
                {Object.keys(thirdColumn).length !== 0 && thirdColumn?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{thirdColumn?.betNameKr || ''}</span>
                  <b>{thirdColumn.priceNum?.toFixed(2) || ''}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 29 - Double Result
const getDoBetType29 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 30 - 가장 점수가 높은 시기
const getDoBetType30 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        let size = 1

        if (betLine.bets.length % 3 === 0) {
          size = 3
        } else if (betLine.bets.length % 2 === 0) {
          size = 2
        }

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <>
                      {betList.map(bet => {
                        return (
                          <li
                            className={bet.isActive ? 't-name-on' : 't-name'}
                            style={{ width: '26%' }}
                            onClick={() => {
                              if (bet?.betStatusId !== 1) return

                              onClickAddBettingCartHandler(
                                createBettingCartItem(
                                  fixture,
                                  {
                                    ...market,
                                    bets: betLine.bets,
                                  },
                                  bet,
                                ),
                              )
                            }}
                          >
                            {bet?.betStatusId !== 1 && (
                              <SportsEventContentItemOverlay>
                                <SportsEventContentItemLockIcon />
                              </SportsEventContentItemOverlay>
                            )}
                            <div className="odds">
                              <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                              <b>{bet.priceNum?.toFixed(2) || 0}</b>
                            </div>
                          </li>
                        )
                      })}
                    </>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 31 - 승패 - 언더오버
const getDoBetType31 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const homeData = betLine?.bets[0] || {}
        const drawData = {}
        const awayData = betLine?.bets[1] || {}

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 32 - 승무패 - 언더오버
const getDoBetType32 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 33 - 승무패 - 더블찬스
const getDoBetType33 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 3

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <>
                      {betList.map(bet => {
                        return (
                          <li
                            className={bet.isActive ? 't-name-on' : 't-name'}
                            style={{ width: '26%' }}
                            onClick={() => {
                              if (bet?.betStatusId !== 1) return

                              onClickAddBettingCartHandler(
                                createBettingCartItem(
                                  fixture,
                                  {
                                    ...market,
                                    bets: betLine.bets,
                                  },
                                  bet,
                                ),
                              )
                            }}
                          >
                            {bet?.betStatusId !== 1 && (
                              <SportsEventContentItemOverlay>
                                <SportsEventContentItemLockIcon />
                              </SportsEventContentItemOverlay>
                            )}
                            <div className="odds">
                              <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                              <b>{bet.priceNum?.toFixed(2) || 0}</b>
                            </div>
                          </li>
                        )
                      })}
                    </>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 34 -승무패 양팀 모두 득점
const getDoBetType34 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="gtype1">
                  <table>
                    <tbody>
                      <tr>
                        <td className="state">{market.marketName}</td>
                        <td
                          className={homeData.isActive ? 'home_pick' : 'home'}
                          onClick={() => {
                            if (homeData?.betStatusId !== 1) return

                            onClickAddBettingCartHandler(
                              createBettingCartItem(
                                fixture,
                                {
                                  ...market,
                                  bets: betLine.bets,
                                },
                                homeData,
                              ),
                            )
                          }}
                        >
                          {homeData?.betStatusId !== 1 && (
                            <SportsEventContentItemOverlay>
                              <SportsEventContentItemLockIcon />
                            </SportsEventContentItemOverlay>
                          )}
                          <span>{homeData.betNameKr}</span>
                          <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                        </td>
                        <td className="draw_hc">
                          <b>
                            {homeData?.baseLineNum?.toFixed(1) ??
                              homeData?.baseLine ??
                              awayData?.baseLineNum?.toFixed(1) ??
                              awayData?.baseLine ??
                              'VS'}
                          </b>
                        </td>
                        <td
                          className={awayData.isActive ? 'away_pick' : 'away'}
                          onClick={() => {
                            if (awayData?.betStatusId !== 1) return

                            onClickAddBettingCartHandler(
                              createBettingCartItem(
                                fixture,
                                {
                                  ...market,
                                  bets: betLine.bets,
                                },
                                awayData,
                              ),
                            )
                          }}
                        >
                          {awayData?.betStatusId !== 1 && (
                            <SportsEventContentItemOverlay>
                              <SportsEventContentItemLockIcon />
                            </SportsEventContentItemOverlay>
                          )}
                          <span>{awayData.betNameKr}</span>
                          <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 35 -언더오버 및  양팀 모두 득점
const getDoBetType35 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 36 - 더블찬스 및 언더오버
const getDoBetType36 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 37 - 더블찬스 및 양팀 모두 득점
const getDoBetType37 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 38 - What will happen first? 1 min 기준점 있음
const getDoBetType38 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>
                          {bet.betNameKr} ( {bet?.lineNum?.toFixed(1) ?? bet?.line} )
                        </span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 39 - What will happen first? 5 min 기준점 있음
const getDoBetType39 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>
                          {bet.betNameKr} ( {bet?.lineNum?.toFixed(1) ?? bet?.line} )
                        </span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 40 - 득점이 가장 높은 시기
const getDoBetType40 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 41 - Winning Division
const getDoBetType41 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 42 - To Break Serve 기준점 있음
const getDoBetType42 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 43 - To score in every Period
const getDoBetType43 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const size = 2

        const chunkedList = betLine.bets.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / size)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // 새로운 청크 시작
          }

          resultArray[chunkIndex].push(item) // 현재 청크에 아이템 추가

          return resultArray
        }, [])

        return (
          <>
            {chunkedList.map(betList => {
              const homeData = betList[0] || {}
              const drawData = {}
              const awayData = betList[1] || {}

              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={homeData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (homeData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            homeData,
                          ),
                        )
                      }}
                    >
                      {homeData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{homeData.betNameKr}</span>
                        <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>
                          {homeData?.baseLineNum?.toFixed(1) ??
                            homeData?.baseLine ??
                            awayData?.baseLineNum?.toFixed(1) ??
                            awayData?.baseLine ??
                            'VS'}
                        </b>
                      </div>
                    </li>
                    <li
                      className={awayData.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        if (awayData?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            awayData,
                          ),
                        )
                      }}
                    >
                      {awayData?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'right', marginRight: '3px' }}>{awayData.betNameKr}</span>
                        <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 44 - First Score Method
const getDoBetType44 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>
                          {bet.betNameKr} ( {bet?.lineNum?.toFixed(1) ?? bet?.line} )
                        </span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 45 - Score Method 6 Way
const getDoBetType45 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 46 - First Score Before/After 기준점 있는거
const getDoBetType46 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        const homeData = betLine?.bets[0] || {}
        const drawData = {}
        const awayData = betLine?.bets[1] || {}

        return (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b>{market.marketName}</b>
              </li>
              <li
                className={homeData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (homeData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      homeData,
                    ),
                  )
                }}
              >
                {homeData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'left', marginLeft: '3px' }}>
                    {homeData.betNameKr} ( {homeData?.lineNum?.toFixed(1) ?? homeData?.line} )
                  </span>
                  <b>{homeData.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>
                    {homeData?.baseLineNum?.toFixed(1) ??
                      homeData?.baseLine ??
                      awayData?.baseLineNum?.toFixed(1) ??
                      awayData?.baseLine ??
                      'VS'}
                  </b>
                </div>
              </li>
              <li
                className={awayData.isActive ? 't-name-on' : 't-name'}
                style={{ width: '29%' }}
                onClick={() => {
                  if (awayData?.betStatusId !== 1) return

                  onClickAddBettingCartHandler(
                    createBettingCartItem(
                      fixture,
                      {
                        ...market,
                        bets: betLine.bets,
                      },
                      awayData,
                    ),
                  )
                }}
              >
                {awayData?.betStatusId !== 1 && (
                  <SportsEventContentItemOverlay>
                    <SportsEventContentItemLockIcon />
                  </SportsEventContentItemOverlay>
                )}
                <div className="odds">
                  <span style={{ float: 'right', marginRight: '3px' }}>
                    ( {awayData?.lineNum?.toFixed(1) ?? awayData?.line} ) {awayData.betNameKr}
                  </span>
                  <b>{awayData?.priceNum?.toFixed(2) || 0}</b>
                </div>
              </li>
            </div>
          </div>
        )
      })}
    </>
  )
}

// type 47 - First Scoring Play
const getDoBetType47 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 48 - Drive Result - AMF
const getDoBetType48 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 49 - When Will The Fight End
const getDoBetType49 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}
// type 50 - Fight Outcome
const getDoBetType50 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}
// type 51 - Round Betting
const getDoBetType51 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}
// type 52 - Group Round Betting
const getDoBetType52 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}
// type 53 - Fight Outcome 2
const getDoBetType53 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

// type 54 - Fight Wins in Distance
const getDoBetType54 = (fixture, market, onClickAddBettingCartHandler) => {
  return (
    <>
      {market.betLines.map(betLine => {
        return (
          <>
            {betLine?.bets.map(bet => {
              return (
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>{market.marketName}</b>
                    </li>
                    <li
                      className={bet.isActive ? 't-name-on' : 't-name'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (bet?.betStatusId !== 1) return

                        onClickAddBettingCartHandler(
                          createBettingCartItem(
                            fixture,
                            {
                              ...market,
                              bets: betLine.bets,
                            },
                            bet,
                          ),
                        )
                      }}
                    >
                      {bet?.betStatusId !== 1 && (
                        <SportsEventContentItemOverlay>
                          <SportsEventContentItemLockIcon />
                        </SportsEventContentItemOverlay>
                      )}
                      <div className="odds">
                        <span style={{ float: 'left', marginLeft: '3px' }}>{bet.betNameKr}</span>
                        <b>{bet.priceNum?.toFixed(2) || 0}</b>
                      </div>
                    </li>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </>
  )
}

export const MoblieDomesticBetBox = React.memo(({ fixtureInfo, marketInfo, onClickAddBettingCartHandler }) => {
  const getBetComponenet = (fixture, market) => {
    switch (market.marketFamilyType) {
      case 0:
        return getDoBetType0(fixture, market, onClickAddBettingCartHandler)
      case 1:
        return getDoBetType1(fixture, market, onClickAddBettingCartHandler)
      case 2:
        return getDoBetType2(fixture, market, onClickAddBettingCartHandler)
      case 3:
        return getDoBetType3(fixture, market, onClickAddBettingCartHandler)
      case 4:
        return getDoBetType4(fixture, market, onClickAddBettingCartHandler)
      case 5:
        return getDoBetType5(fixture, market, onClickAddBettingCartHandler)
      case 6:
        return getDoBetType6(fixture, market, onClickAddBettingCartHandler)
      case 7:
        return getDoBetType7(fixture, market, onClickAddBettingCartHandler)
      case 8:
        return getDoBetType8(fixture, market, onClickAddBettingCartHandler)
      case 9:
        return getDoBetType9(fixture, market, onClickAddBettingCartHandler)
      case 10:
        return getDoBetType10(fixture, market, onClickAddBettingCartHandler)
      case 11:
        return getDoBetType11(fixture, market, onClickAddBettingCartHandler)
      case 12:
        return getDoBetType12(fixture, market, onClickAddBettingCartHandler)
      case 13:
        return getDoBetType13(fixture, market, onClickAddBettingCartHandler)
      case 14:
        return getDoBetType14(fixture, market, onClickAddBettingCartHandler)
      case 15:
        return getDoBetType15(fixture, market, onClickAddBettingCartHandler)
      case 16:
        return getDoBetType16(fixture, market, onClickAddBettingCartHandler)
      case 17:
        return getDoBetType17(fixture, market, onClickAddBettingCartHandler)
      case 18:
        return getDoBetType18(fixture, market, onClickAddBettingCartHandler)
      case 19:
        return getDoBetType19(fixture, market, onClickAddBettingCartHandler)
      case 20:
        return getDoBetType20(fixture, market, onClickAddBettingCartHandler)
      case 21:
        return getDoBetType21(fixture, market, onClickAddBettingCartHandler)
      case 22:
        return getDoBetType22(fixture, market, onClickAddBettingCartHandler)
      case 23:
        return getDoBetType23(fixture, market, onClickAddBettingCartHandler)
      case 24:
        return getDoBetType24(fixture, market, onClickAddBettingCartHandler)
      case 25:
        return getDoBetType25(fixture, market, onClickAddBettingCartHandler)
      case 26:
        return getDoBetType26(fixture, market, onClickAddBettingCartHandler)
      case 27:
        return getDoBetType27(fixture, market, onClickAddBettingCartHandler)
      case 28:
        return getDoBetType28(fixture, market, onClickAddBettingCartHandler)
      case 29:
        return getDoBetType29(fixture, market, onClickAddBettingCartHandler)
      case 30:
        return getDoBetType30(fixture, market, onClickAddBettingCartHandler)
      case 31:
        return getDoBetType31(fixture, market, onClickAddBettingCartHandler)
      case 32:
        return getDoBetType32(fixture, market, onClickAddBettingCartHandler)
      case 33:
        return getDoBetType33(fixture, market, onClickAddBettingCartHandler)
      case 34:
        return getDoBetType34(fixture, market, onClickAddBettingCartHandler)
      case 35:
        return getDoBetType35(fixture, market, onClickAddBettingCartHandler)
      case 36:
        return getDoBetType36(fixture, market, onClickAddBettingCartHandler)
      case 37:
        return getDoBetType37(fixture, market, onClickAddBettingCartHandler)
      case 38:
        return getDoBetType38(fixture, market, onClickAddBettingCartHandler)
      case 39:
        return getDoBetType39(fixture, market, onClickAddBettingCartHandler)
      case 40:
        return getDoBetType40(fixture, market, onClickAddBettingCartHandler)
      case 41:
        return getDoBetType41(fixture, market, onClickAddBettingCartHandler)
      case 42:
        return getDoBetType42(fixture, market, onClickAddBettingCartHandler)
      case 43:
        return getDoBetType43(fixture, market, onClickAddBettingCartHandler)
      case 44:
        return getDoBetType44(fixture, market, onClickAddBettingCartHandler)
      case 45:
        return getDoBetType45(fixture, market, onClickAddBettingCartHandler)
      case 46:
        return getDoBetType46(fixture, market, onClickAddBettingCartHandler)
      case 47:
        return getDoBetType47(fixture, market, onClickAddBettingCartHandler)
      case 48:
        return getDoBetType48(fixture, market, onClickAddBettingCartHandler)
      case 49:
        return getDoBetType49(fixture, market, onClickAddBettingCartHandler)
      case 50:
        return getDoBetType50(fixture, market, onClickAddBettingCartHandler)
      case 51:
        return getDoBetType51(fixture, market, onClickAddBettingCartHandler)
      case 52:
        return getDoBetType52(fixture, market, onClickAddBettingCartHandler)
      case 53:
        return getDoBetType53(fixture, market, onClickAddBettingCartHandler)
      case 54:
        return getDoBetType54(fixture, market, onClickAddBettingCartHandler)
      default:
        return getDoBetTypeEmpty(fixture, market, onClickAddBettingCartHandler)
    }
  }

  return (
    <Fragment key={`${fixtureInfo.fixtureId}-${marketInfo.marketId}`}>
      {getBetComponenet(fixtureInfo, marketInfo)}
    </Fragment>
  )
})

const SportsEventContentItem = styled.div`
  width: 100%;
  min-height: 36px;
  height: auto;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

const SportsEventContentItemMarketName = styled.div`
  width: 12%;
  min-height: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 1);
  border: 2px solid #2d353c;
  color: white;
  font-weight: 600;
  text-align: center;
  word-break: keep-all;
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
    justify-content: start;
    padding-left: 10px;
    `
      : `
    justify-content: end;
    padding-right: 10px;
    `}
`

const FixtureMarketBetBoxPrice = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${props =>
    props.type === 'home-box'
      ? `
      padding-right: 10px;
      img {
        margin-right: 5px;
      }
    `
      : `
      padding-left: 10px;
      img {
        margin-left: 5px;
      }
    `}
`

const FixtureMarketBetLineBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.fd || 'column'};
`

const FixtureMarketBetBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const FixtureMarketBetBox = styled.div`
  width: ${props => props.wd || 'calc(50% - 39px);'};
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  ${props =>
    !props.empty &&
    `
        &:hover {
            cursor: pointer;
            background: ${HermesMainSelect};
            background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }  
    `}

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`

const SportsEventContentItemPoint = styled.div`
  width: 100px;
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`
