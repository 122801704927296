import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import {
  fetchLiveFixtureListAction,
  fetchLiveFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketAdditionalOption,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsLiveInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { MobileSportEventNotInProgressFixtures } from './MobileSportEventNotInProgressFixtures'
import { MobileSportEventInProgressFixtures } from './MobileSportEventInProgressFixtures'

const MobileSportsLiveContent = () => {
  const dispatch = useDispatch()

  const { totalElements, liveParams, liveMarketParams, liveFixtures } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      totalElements: sportsLiveInfo.totalElements,
      liveParams: sportsLiveInfo.liveParams,
      liveMarketParams: sportsLiveInfo.liveMarketParams,
      liveFixtures: sportsLiveInfo.liveFixtures,
    }
  })

  const [liveInProgressFixtures, setLiveInProgressFixtures] = useState({})
  const [liveNotInProgressFixtures, setLiveNotInProgressFixtures] = useState({})

  useEffect(() => {
    const tempLiveInProgressFixtures = {}
    const tempLiveNotInProgressFixtures = {}

    Object.entries(liveFixtures).forEach(([key, event]) => {
      const currentTime = moment()
      const startDate = moment(event.startDate)

      const diff = currentTime.diff(startDate, 'seconds')

      if (diff < 0) {
        //  경기 예정
        tempLiveNotInProgressFixtures[key] = event
      } else {
        // 시작된 경기
        tempLiveInProgressFixtures[key] = event
      }
    })

    setLiveInProgressFixtures(tempLiveInProgressFixtures)
    setLiveNotInProgressFixtures(tempLiveNotInProgressFixtures)
  }, [liveFixtures])

  const { bettingCartList, bettingOkTimeCriteria } = useSelector(state => {
    const { sportsBetting } = state

    const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.LIVE]

    return {
      bettingCartList: sportsBetting.bettingCartList,
      bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
    }
  })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / liveParams.size))
  }, [totalElements, liveParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))

    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    dispatch(fetchLiveFixtureListAction())
  }, [dispatch, liveParams])

  useEffect(() => {
    dispatch(fetchLiveFixtureMarketAction())
  }, [dispatch, liveMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const [selectedFixtureInfo, setSelectedFixtureInfo] = useState(null)
  const selectedFixtureInfoRef = useRef(selectedFixtureInfo)

  useEffect(() => {
    selectedFixtureInfoRef.current = selectedFixtureInfo
  }, [selectedFixtureInfo])

  const onClickSportFixtureMarketAdditionalOptionHandler = useCallback(
    (eventKey, fixtureData) => {
      const diff = !isEqual(selectedFixtureInfoRef.current, {
        eventKey,
        fixtureId: fixtureData.fixtureId,
      })

      if (diff) {
        dispatch(setFixtureMarketParam(fixtureData.fixtureId))
        dispatch(
          setFixtureMarketAdditionalOption({
            key: eventKey,
            fixtureId: fixtureData.fixtureId,
          }),
        )
        setSelectedFixtureInfo({
          eventKey,
          fixtureId: fixtureData.fixtureId,
        })
      } else {
        dispatch(
          setFixtureMarketAdditionalOption({
            key: eventKey,
            fixtureId: fixtureData.fixtureId,
          }),
        )
        setSelectedFixtureInfo(null)
      }
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.LIVE,
        }),
      )
    },
    [dispatch],
  )

  return (
    <div id="game_list">
      {/* 게임 리스트 */}
      {Object.keys(liveInProgressFixtures).length !== 0 && (
        <SportsInProgressWrap>
          {Object.entries(liveInProgressFixtures).map(([key, event]) => {
            const filteredFixtures = event.fixtures.filter(fixtureItem => {
              return (
                fixtureItem.fixture.fixtureVisible &&
                fixtureItem.fixture.sportVisible &&
                fixtureItem.fixture.locationVisible &&
                fixtureItem.fixture.leagueVisible &&
                fixtureItem.fixture.homeVisible &&
                fixtureItem.fixture.awayVisible &&
                fixtureItem.fixture.fixtureStatus === 'IN_PROGRESS'
              )
            })

            if (filteredFixtures.length === 0) return null

            const customEvent = {
              ...event,
              fixtures: [...filteredFixtures],
            }

            return (
              <MobileSportEventInProgressFixtures
                eventKey={key}
                event={customEvent}
                onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                onClickAddBettingCartHandler={onClickAddBettingCartHandler}
              />
            )
          })}
        </SportsInProgressWrap>
      )}

      {Object.keys(liveNotInProgressFixtures).length !== 0 && (
        <SportsNotInProgressWrap>
          <SportsNotInProgressTextBox>진행예정경기</SportsNotInProgressTextBox>
          {Object.entries(liveNotInProgressFixtures).map(([key, event]) => {
            return (
              <MobileSportEventNotInProgressFixtures
                eventKey={key}
                event={event}
                onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
              />
            )
          })}
        </SportsNotInProgressWrap>
      )}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomPagination currentPage={liveParams.page} totalPages={totalPages} onPageChange={onPageChange} />
      </div>
    </div>
  )
}

export default MobileSportsLiveContent

const SportsInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

const SportsNotInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

// 진행예정경기 문구
const SportsNotInProgressTextBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: #000;
  margin-top: 20px;
  background: linear-gradient(#fff, #f5fcff);
  border: 1px solid #eee;
`
