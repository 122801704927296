import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { searchMemberMoneyWithdrawal } from '../../api/memberHistory/memberHistoryApi'
import {
  searchWithdrawalRule,
  createUserMoneyWithdrawal,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
} from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstDateTime } from '../../utils/dateTime'
import { BonusTypeEnums, MemberDepositStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { colorMainText } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import MoneyWithdrawalListData from '../MyPage/MoneyWithdrawalHistory/MoneyWithdrawalListData'
import WithdrawalDeleteRadio from './WithdrawalDeleteRadio'

const MoneyWithdrawalForm = () => {
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   * 회원 머니출금내역 조회
   */
  const [checkRadio, setCheckRadio] = useState([])
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyWithdrawal(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
    setScrollPosition(window.scrollY) // Store the current scroll position

    // if (sportsBettingHistoryWrapRef.current) {
    //   sportsBettingHistoryWrapRef.current.scrollTop = 10
    // }

    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  useEffect(() => {
    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = scrollPosition // Set scroll position back after page change
    }
  }, [page, scrollPosition])

  const fetchSearchMemberMoneyWithdrawal = async () => {
    setLoading(true)
    await searchMemberMoneyWithdrawal(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [moneyWithdrawalListData, setMoneyWithdrawalListData] = useState(MoneyWithdrawalListData(content))

  useEffect(() => {
    setMoneyWithdrawalListData(MoneyWithdrawalListData(content))
  }, [content])

  const [rows, setData] = useState(moneyWithdrawalListData.tableRowsData)
  useEffect(() => {
    setData(moneyWithdrawalListData.tableRowsData)
  }, [moneyWithdrawalListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  const [withdrawalRuleContent, setWithdrawalRuleContent] = useState('')
  const [bonusPer, setBonusPer] = useState(0)
  const [bonusType, setBonusType] = useState(null)
  const [totalDepositAmount, setTotalDepositAmount] = useState(0)
  const [withdrawRollingStatus, setWithdrawRollingStatus] = useState(null)
  const [currentRollingRatio, setCurrentRollingRatio] = useState(0)
  const [necessarySportsSingleLiveBettingAmount, setNecessarySportsSingleLiveBettingAmount] = useState(0)
  const [necessarySportsTwoBettingAmount, setNecessarySportsTwoBettingAmount] = useState(0)
  const [necessarySportsMultiBettingAmount, setNecessarySportsMultiBettingAmount] = useState(0)
  const [necessaryVirtualBettingAmount, setNecessaryVirtualBettingAmount] = useState(0)
  const [necessaryMiniGameBettingAmount, setNecessaryMiniGameBettingAmount] = useState(0)
  const [necessaryCasinoBettingAmount, setNecessaryCasinoBettingAmount] = useState(0)
  const [necessarySlotBettingAmount, setNecessarySlotBettingAmount] = useState(0)
  const [necessaryHoldemBettingAmount, setNecessaryHoldemBettingAmount] = useState(0)

  useEffect(() => {
    setMyInfoLoading(true)
    searchWithdrawalRule().then(res => {
      setWithdrawalRuleContent(res.data.withdrawalRuleContent)
      setBonusPer(res.data.withdrawalRollingModel.bonusPer)
      setBonusType(res.data.withdrawalRollingModel.bonusType)
      setTotalDepositAmount(res.data.withdrawalRollingModel.totalDepositAmount)
      setWithdrawRollingStatus(res.data.withdrawalRollingModel.withdrawRollingStatus)
      setCurrentRollingRatio(res.data.withdrawalRollingModel.currentRollingRatio)
      setNecessarySportsSingleLiveBettingAmount(res.data.withdrawalRollingModel.necessarySportsSingleLiveBettingAmount)
      setNecessarySportsTwoBettingAmount(res.data.withdrawalRollingModel.necessarySportsTwoBettingAmount)
      setNecessarySportsMultiBettingAmount(res.data.withdrawalRollingModel.necessarySportsMultiBettingAmount)
      setNecessaryVirtualBettingAmount(res.data.withdrawalRollingModel.necessaryVirtualBettingAmount)
      setNecessaryMiniGameBettingAmount(res.data.withdrawalRollingModel.necessaryMiniGameBettingAmount)
      setNecessaryCasinoBettingAmount(res.data.withdrawalRollingModel.necessaryCasinoBettingAmount)
      setNecessarySlotBettingAmount(res.data.withdrawalRollingModel.necessarySlotBettingAmount)
      setNecessaryHoldemBettingAmount(res.data.withdrawalRollingModel.necessaryHoldemBettingAmount)
    })
    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  // 출금 암호
  // 비밀번호
  const [withdrawalPassword, setWithdrawalPassword] = useState('')

  // 출금 금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const onSubmit = e => {
    if (apiFlag) return
    if (!withdrawalPassword) {
      alert('출금암호를 입력해주세요.')
      return
    }
    if (withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE') {
      alert('롤링 완료 후 출금이 가능합니다.')
      return
    }
    const checkMoney = applyMoney?.toString().replaceAll(',', '')

    if (Number(checkMoney) > Number(holdingMoney)) {
      alert('보유머니를 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원단위로 입력이 가능합니다.')
      return
    }
    setApiFlag(true)

    const body = {
      applyMoney: checkMoney,
      withdrawalPassword,
    }

    createUserMoneyWithdrawal(body)
      .then(res => {
        alert('출금신청이 완료되었습니다.')
        setApiFlag(false)
        fetchSearchMemberMoneyWithdrawal()
        dispatch(fetchMemberInfo())
        setApplyMoney('0')
        setWithdrawalPassword('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1011':
            alert('출금이 불가능한 시간입니다.')
            break
          case 'ASSET-1005':
            alert(message)
            break
          case 'ASSET-1006':
            alert('출금 후 재출금 대기시간은 3시간 입니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          case 'ASSET-1013':
            alert(message)
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
        dispatch(fetchMemberInfo())
        setApplyMoney('0')
        setWithdrawalPassword('')
        fetchSearchMemberMoneyWithdrawal()
      })
  }

  const onDeleteHandler = () => {
    if (loading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  function formatAmount(amount) {
    if (amount != null) {
      return amount >= 0 ? `${amount.toString().replace(commonReg2, ',')} 원` : '0 원'
    }
    return '0 원'
  }

  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <table className="tb_notice_box">
              <tbody>
                <tr>
                  <td className="td_notice_td">
                    <div style={{ margin: '0px', padding: '0px' }}>
                      <div style={{ margin: '0px', padding: '0px' }}>
                        {ReactHtmlParser(withdrawalRuleContent, { transform: transformFn })}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="height_50" />

            {/* 신청 */}
            <div>
              <table className="tb_solid_table">
                <tbody>
                  <tr>
                    <td className="title">예금주</td>
                    <td className="body">
                      <span id="CPH_bank_name" className="text_black">
                        {userName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">출금금액</td>
                    <td className="body">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                name="money_box"
                                type="text"
                                className="input_money_box"
                                id="money_box"
                                style={{ width: '130px', paddingRight: '5px' }}
                                value={applyMoney}
                                readOnly="readonly"
                              />
                            </td>
                            <td className="td_buttons">
                              <span style={{ color: '#fff' }}>원</span>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('10000')
                                }}
                              >
                                1만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('50000')
                                }}
                              >
                                5만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('100000')
                                }}
                              >
                                10만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('500000')
                                }}
                              >
                                50만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('1000000')
                                }}
                              >
                                100만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClearClickHandler()
                                }}
                              >
                                초기화
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">출금비번</td>
                    <td className="body">
                      <input
                        id="exchange_pass"
                        name="exchange_pass"
                        type="password"
                        className="input_pass"
                        style={{ width: '130px', paddingRight: '5px' }}
                        value={withdrawalPassword}
                        onChange={e => {
                          setWithdrawalPassword(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">최근 입금액(보너스 포함)</td>
                    <td className="body">{totalDepositAmount?.toString().replace(commonReg2, ',')}원</td>
                  </tr>
                  <tr>
                    <td className="title">최근 보너스(비율)</td>
                    <td className="body">
                      {BonusTypeEnums[bonusType]} - {bonusPer}%
                    </td>
                  </tr>
                  <tr>
                    <td className="title">롤링 진행율</td>
                    <td className="body">
                      <span style={{ color: currentRollingRatio < 100 ? 'red' : 'green' }}>{currentRollingRatio}%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">필요 배팅금액</td>
                    <td className="body" style={{ padding: '0px', margin: '0px', height: '40px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          padding: '0px',
                          height: '40px',
                        }}
                      >
                        <div style={{ display: 'flex', width: '100%', height: '20px' }}>
                          <CustomSpan2>스포츠 인플레이/단폴더</CustomSpan2>
                          {/* <CustomSpan2>스포츠 두폴더</CustomSpan2> */}
                          <CustomSpan2>스포츠 두폴이상</CustomSpan2>
                          <CustomSpan2>미니게임</CustomSpan2>
                          <CustomSpan2>카지노</CustomSpan2>
                          <CustomSpan2 style={{ borderRight: 'none' }}>슬롯</CustomSpan2>
                        </div>
                        <div style={{ display: 'flex', height: '20px' }}>
                          <CustomSpan3>{formatAmount(necessarySportsSingleLiveBettingAmount)}</CustomSpan3>
                          {/* <CustomSpan3>{formatAmount(necessarySportsTwoBettingAmount)}</CustomSpan3> */}
                          <CustomSpan3>{formatAmount(necessarySportsMultiBettingAmount)}</CustomSpan3>
                          <CustomSpan3>{formatAmount(necessaryMiniGameBettingAmount)}</CustomSpan3>
                          <CustomSpan3>{formatAmount(necessaryCasinoBettingAmount)}</CustomSpan3>
                          <CustomSpan3 style={{ borderRight: 'none' }}>
                            {formatAmount(necessarySlotBettingAmount)}
                          </CustomSpan3>
                        </div>
                      </div>
                      {/* <table style={{ border: 'solid 1px blue' }}>
                        <tbody style={{ border: 'solid 1px red', width: '100%' }}>
                          <tr>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                          </tr>
                        </tbody>
                      </table> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="div_button">
              <div style={{ height: '30px' }} />
              <a className="big_button" style={{ width: '200px' }} onClick={onSubmit}>
                환전신청
              </a>
            </div>

            {/* 신청 내역 */}
            <div className="div_list" style={{ marginBottom: '10px' }} ref={sportsBettingHistoryWrapRef}>
              <div style={{ display: 'flex', gap: '5px', margin: '0 0 5px 0', float: 'right' }}>
                <a
                  className="btMoney"
                  style={{ width: '66px' }}
                  onClick={() => {
                    setCheckRadio(content.map(value => value.moneyWithdrawalKey))
                  }}
                >
                  전체선택
                </a>
                <a
                  className="btMoney"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onDeleteHandler()
                  }}
                >
                  삭제하기
                </a>
              </div>
              <table style={{ textAlign: 'center' }}>
                <colgroup>
                  <col style={{ width: '9%' }} />
                  <col style={{ width: '17%' }} />
                  <col style={{ width: '41%' }} />
                  <col style={{ width: '19%' }} />
                  <col style={{ width: '14%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>삭제</th>
                    <th>아이디</th>
                    <th>지급액</th>
                    <th>처리일자</th>
                    <th>처리상태</th>
                  </tr>
                </thead>
                <tbody>
                  {totalElement > 0 ? (
                    <>
                      {content.map(item => {
                        return (
                          <tr>
                            <td>
                              {item.status === 'APPLY' ? (
                                <>-</>
                              ) : (
                                <>
                                  <WithdrawalDeleteRadio
                                    rowInfo={{
                                      moneyWithdrawalKey: item.moneyWithdrawalKey,
                                    }}
                                    setCheckRadio={setCheckRadio}
                                    checkRadio={checkRadio}
                                  />
                                </>
                              )}
                            </td>
                            <td>{item.memberId}</td>
                            <td>{item.applyMoney?.toString().replace(commonReg2, ',')}</td>
                            <td>{convertToKstDateTime(item.executeDate)}</td>
                            <td>{MemberDepositStatusEnums[item.status]}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5">최근 입금요청 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalElement > 0 && (
              <>
                <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MoneyWithdrawalForm

// endregion

const CustomSpan2 = styled.span`
  width: 20%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #9a9a9a;
  border-right: solid 1px #9a9a9a;
  color: #000;
  font-weight: 500;
  background: linear-gradient(#ffffff, #fff9f9);
`

const CustomSpan3 = styled.span`
  width: 20%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-right: solid 1px #9a9a9a;
  color: #000;
`
const MoneyWithdrawalHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const MoneyWithdrawalWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;
  margin-top: 10px;
`

const MoneyWithdrawalHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px !important;
  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyWithdrawalCardSubhead = styled.p`
  text-transform: none;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

const MoneyWithdrawalBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MoneyWithdrawalInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
