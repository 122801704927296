import { CustomSelect } from '@/shared/components/CustomSelect'
import { FormGroupField } from '@/shared/components/form/FormElements'
import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyDeposit,
  searchDepositRule,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
} from '../../api/memberManage/memberManageApi'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { CustomButtonInfo } from '../../shared/components/MobileMoneyCustomElement'
import { MoneyFormModifyButtomInfo } from '../../shared/components/form/newForm'
import { convertToKstByFormat } from '../../utils/dateTime'
import { MemberDepositStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { HermesMainBorder, colorMainText } from '../../utils/palette'
import MobileMoneyDepositListData from '../mobileMyPage/mobileMoneyDepositHistory/MobileMoneyDepositListData'
import { createOnetoOne } from '../../api/customCenter/customCenterApi'

const MobileMoneyDepositForm = ({ visible, onClose }) => {
  const navigate = useNavigate()
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  /**
   *  회원 입금내역 조회
   */
  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyDeposit(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyDeposit = async () => {
    setLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [depositListData, setDepositListData] = useState(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))

  useEffect(() => {
    setDepositListData(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(depositListData.tableRowsData)

  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  const [depositRuleContent, setDepositRuleContent] = useState('')
  const [isShowDepositRuleContent, setIsShowDepositRuleContent] = useState(false)

  const [depositBonusList, setDepositBonusList] = useState([])
  const [isShowDepositBonusMainSelectDrop, setIsShowDepositBonusMainSelectDrop] = useState(false)

  // 선택한 보너스 타입
  const [selectedBonusType, setSelectedBonusType] = useState(null)

  const onClickDepositBonusTypeHandler = key => {
    const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)
    if (data) {
      setSelectedBonusType({
        moneyDepositBonusKey: data.moneyDepositBonusKey,
        title: data.title,
        bonusType: data.bonusType,
      })
    }
    setIsShowDepositBonusMainSelectDrop(false)
  }

  const getDepositBonusImageUrl = type => {
    // hover 때문에 확장자 뺌
    switch (type) {
      case 'BONUS_NONE':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports_etc`
      case 'BONUS_SPORTS':
      case 'BONUS_SPORTS_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports`
      case 'BONUS_CASINO':
      case 'BONUS_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_casino`
      case 'BONUS_SLOT_CASINO':
      case 'BONUS_SLOT_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_slot`
      case 'BONUS_MINI_GAME':
      case 'BONUS_MINI_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_mini`
      case 'BONUS_PLUS':
      case 'BONUS_PLUS_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_all`
      default:
        return ''
    }
  }

  const getSelectedBonusType = () => {
    switch (selectedBonusType?.bonusType) {
      case 'BONUS_NONE':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      default:
        return <span>입금 보너스 선택하기</span>
    }
  }

  useEffect(() => {
    searchDepositRule().then(res => {
      setDepositRuleContent(res.data.depositRule)
      setDepositBonusList(res.data.depositBonusList)
    })
  }, [])

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')

  useEffect(() => {
    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setDepositMaxAmount(res.data.depositMaxAmount)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  // 실입금액
  const [realMoney, setRealMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const checkNumber = checkMoney => {
    return Math.sign(checkMoney)
  }

  const [inquiryButton, setInquiryButton] = useState(false)

  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
    setScrollPosition(window.scrollY) // Store the current scroll position

    // if (sportsBettingHistoryWrapRef.current) {
    //   sportsBettingHistoryWrapRef.current.scrollTop = 10
    // }

    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)
  const onSubmit = e => {
    if (apiFlag) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (!selectedBonusType) {
      alert('보너스 선택을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }
    setApiFlag(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
      bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
    }
    setLoading(true)
    createUserMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.')
        setApiFlag(false)
        fetchSearchMemberMoneyDeposit()
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역 탭에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }
        fetchSearchMemberMoneyDeposit()
        setApiFlag(false)
      })
  }
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = moneyDepositKey => {
    if (deleteLoading) return
    // if (checkRadio.length === 0) {
    //   alert('삭제 할 목록을 선택해주세요.')
    //   return
    // }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: [moneyDepositKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          // setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          // setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '⚡ 자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        navigate('/mobile/onetoone')
        setQuestionLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="/m/Default.aspx">
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>
        <div className="body_title">
          <span className="title1">
            <strong>충전요청</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Charge Request</strong>
          </span>
        </div>
        <div className="mypage_wrap">
          <div className="charge_box">
            <table align="center" cellPadding="0" cellSpacing="0" border="0" width="100%" className="charge_box_table">
              <tbody>
                <tr>
                  <td className="charge_box_td1">&nbsp;계좌번호</td>
                  <td className="charge_box_td2">
                    <a
                      onClick={() => {
                        onQuestionAccountHandler()
                      }}
                    >
                      <font className="bt_type1" style={{ width: '100px', fontWeight: '100' }}>
                        자동 계좌문의
                      </font>
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;예금주</td>
                  <td className="charge_box_td2">
                    <input name="charge_name" type="hidden" id="charge_name" value="" readOnly="readonly" />
                    <span style={{ color: '#959595', fontSize: '12px' }}>
                      <b>(정보보호를 위해 공개하지 않습니다)</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;요청금액</td>
                  <td className="charge_box_td2">
                    <table
                      align="center"
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      width="100%"
                      className="charge_box_money"
                    >
                      <tbody>
                        <tr>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('10000')
                            }}
                          >
                            1만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('30000')
                            }}
                          >
                            3만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('50000')
                            }}
                          >
                            5만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('100000')
                            }}
                          >
                            10만원
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('300000')
                            }}
                          >
                            30만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('500000')
                            }}
                          >
                            50만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('1000000')
                            }}
                          >
                            100만원
                          </td>
                          <td
                            onClick={() => {
                              onClearClickHandler()
                            }}
                            className="charge_box_money_cancel"
                          >
                            <b>
                              <span style={{ color: '#000' }}>초기화</span>
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">보너스 선택</td>
                  <td className="charge_box_td2" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <DepositBonusSelectWrap>
                      <DepositBonusSelectInnerWrap>
                        <DepositBonusMainSelectWrap
                          onClick={() => {
                            setIsShowDepositBonusMainSelectDrop(prev => !prev)
                          }}
                        >
                          {getSelectedBonusType()}
                          <CustomMdKeyboardArrowDown />
                        </DepositBonusMainSelectWrap>
                        {isShowDepositBonusMainSelectDrop && (
                          <DepositBonusMainSelectDropWrap>
                            {/* 아래부터 반복 */}
                            {depositBonusList.map(item => (
                              <DepositBonusMainSelectDropItemWrap>
                                <DepositBonusMainSelectDropItemBox
                                  url={getDepositBonusImageUrl(item.bonusType)}
                                  onClick={() => {
                                    onClickDepositBonusTypeHandler(item.moneyDepositBonusKey)
                                  }}
                                >
                                  <DepositBonusMainSelectDropItemImg />
                                  <DepositBonusMainSelectDropItemTextBox sc={item.content}>
                                    <DepositBonusMainSelectDropItemTextContent>
                                      {item.title}
                                    </DepositBonusMainSelectDropItemTextContent>
                                    {item.content && (
                                      <DepositBonusMainSelectDropItemTextSubContent>
                                        {item.content}
                                      </DepositBonusMainSelectDropItemTextSubContent>
                                    )}
                                  </DepositBonusMainSelectDropItemTextBox>
                                </DepositBonusMainSelectDropItemBox>
                              </DepositBonusMainSelectDropItemWrap>
                            ))}
                          </DepositBonusMainSelectDropWrap>
                        )}
                      </DepositBonusSelectInnerWrap>
                    </DepositBonusSelectWrap>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">&nbsp;요청금액</td>
                  <td className="charge_box_td2">
                    <input name="charge_type" id="charge_type_money" type="hidden" />
                    <input
                      name="charge_price"
                      type="text"
                      id="charge_price"
                      style={{
                        width: '150px',
                        height: '23px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        textAlign: 'right',
                        paddingRight: '10px',
                      }}
                      value={applyMoney}
                      onChange={e => {
                        setApplyMoney(e.target.value)
                      }}
                      maxLength="10"
                    />
                    &nbsp;<b style={{ color: '#fff', fontSize: '16px' }}>원</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn_wrap">
            <a
            // href="javascript:charge_money();"
            >
              <font
                className="bt_type1"
                style={{ width: '200px', height: '30px', lineHeight: '30px', fontWeight: '100' }}
                onClick={onSubmit}
              >
                충전신청
              </font>
            </a>
          </div>
          <div style={{ height: '10px' }} />
          <div className="mypage_wrap">
            <table className="mypage_list_table">
              <tbody>
                {totalElement > 0 ? (
                  <>
                    {content.map(item => {
                      return (
                        <tr>
                          <td>
                            {convertToKstByFormat(item.applyDate, 'YY.MM.DD')} [
                            {convertToKstByFormat(item.applyDate, 'HH:mm')}]&nbsp;
                            <b>{item.applyMoney?.toString().replace(commonReg2, ',')} 원</b>&nbsp;&nbsp;
                            {MemberDepositStatusEnums[item.status]}
                          </td>
                          <td style={{ width: '23px', textAlign: 'center' }}>
                            {item.status !== 'APPLY' && (
                              <a
                                onClick={() => {
                                  onDeleteHandler(item.moneyDepositKey)
                                }}
                                // href="javascript:delete_charge_list('1379610')"
                              >
                                <img
                                  style={{ height: '20px', marginTop: '1px' }}
                                  src={`${process.env.PUBLIC_URL}/img/popup/x_button.png`}
                                  alt=""
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan="2">최근 입금요청 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
        </div>
      </div>
    </>
  )
}

export default MobileMoneyDepositForm

export const MobileListButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  padding-left: 5px;
  gap: 5px;
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

const ContentInfo = styled.div`
  width: 100%;
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  font-size: 14px;
  font-weight: 700;
`
const MoneyButton = styled.button`
  width: 90%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

export const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
`

const SignUpButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: none;
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
`

const ContentRowWrap2 = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

const ContentRowWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`
const ContentRowThirdWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px; /* 혹은 다른 높이 값으로 설정 */
`

const ContentRowSecondWrap = styled.div`
  margin-top: 30px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  span {
    color: #fff;
  }
`

const ContentRowSelectWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  background: #202024 !important;
  padding: 10px 5px;
`
const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-size: 12px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`

const ContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    font-family: 'Noto Sans', sans-serif;
    height: 62px;
    width: 80px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100vw;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 45px;
`

const LevelLogo = styled.img`
  width: 50px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  // background: #16171a !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  // font-weight: 300;
`

const CustomDiv = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
`

const CustomFormGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  font-size: 12px;
`
const CustomFormGroupField = styled(FormGroupField)``
const MoneyDepositHeaderThirdInfo = styled.span`
  font-size: 12px;
  line-height: 23px;
  // color: #ffc01a;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Noto Sans', sans-serif;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`

const MemberCouponSelect = styled(CustomSelect)`
  width: 100%;
  .react-select__menu {
    z-index: 101;
  }
  color: black;
  .react-select__menu-list {
    overflow: auto;
  }
`

const MoneyDepositWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;
`

const MoneyDepositHeaderWrap = styled.div`
  height: 130px;

  padding: 0 0 0 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  // margin-top: 3px;
  // margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DepositBonusSelectWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #000;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
`

const DepositBonusSelectInnerWrap = styled.div`
  width: 100%;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectWrap = styled.div`
  width: 100%;
  background: #ddd !important;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  padding: 12px !important;
  box-sizing: border-box;
  display: block;
  border: solid 1px #000;
  span {
    cursor: pointer;
  }
`

const CustomMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  color: #000;
  width: auto;
  height: 25px;
  float: right;
  cursor: pointer;
`

const DepositBonusMainSelectDropWrap = styled.div`
  background: linear-gradient(#fff, #f5fcff);
  position: absolute;
  width: 100%;
  z-index: 300;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemWrap = styled.div`
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  display: block;
  display: flex;
`

const DepositBonusMainSelectDropItemImg = styled.div`
  background-position: 50%;
  background-size: 100% 100%;
  width: 26px;
  height: 26px;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemBox = styled.div`
  color: #111;
  background: linear-gradient(#f5fcff, #fff);
  border: 1px solid #f5b32c;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  cursor: pointer;

  ${DepositBonusMainSelectDropItemImg} {
    background-image: ${props => props.url && `url(${props.url}.png)`};
  }

  &:hover {
    background-color: ${HermesMainBorder};

    ${DepositBonusMainSelectDropItemImg} {
      background-image: ${props => props.url && `url(${props.url}_hover.png)`};
    }
  }
`

const DepositBonusMainSelectDropItemTextBox = styled.div`
  width: calc(100% - 26px);
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.sc ? 'column' : 'row')};
  float: left;
  padding-left: 5px;
  min-height: 26px;
  box-sizing: border-box;
`

const DepositBonusMainSelectDropItemTextContent = styled.div`
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: block;
  font-size: 13px;
`
const DepositBonusMainSelectDropItemTextSubContent = styled.div`
  width: 100%;
  text-align: left;
  font-size: 11px;
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 100%;
`

const MoneyDepositRuleWrap = styled.div`
  background: #141517 !important;
  padding: 24px;
  box-sizing: border-box;
  display: block;
  font-family: 'Noto Sans', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 13px;
`

const MoneyDepositRuleContentWrap = styled.div`
  word-break: keep-all;
`
