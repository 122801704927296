import React, { useEffect, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { bettingSportsEventV2 } from '../../api/game/sportsGameApi'
import CustomAlert from '../../containers/CustomAlert'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  removeAllBettingCartItem,
  removeBettingCartItem,
  setActiveSportsMarketType,
  setBettingCartLoading,
  setSelectedBonusFolder,
  setBettingDelay,
} from '../../redux/sportsBettingSlice'
import { setBalanceActiveBetInfo, setDomesticActiveBetInfo } from '../../redux/sportsDomesticInfoSlice'
import { setLiveActiveBetInfo } from '../../redux/sportsLiveInfoSlice'
import { setOverseasActiveBetInfo } from '../../redux/sportsOverseasInfoSlice'
import { setSpecialActiveBetInfo } from '../../redux/sportsSpecialInfoSlice'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import isLogin from '../../utils/enums/authority'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomBettingAlert from '../../containers/CustomBettingAlert'

const MobileSportsBettingCartSidebar = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  const location = useLocation()

  const { holdingMoney, memberTotalGameMoney, userId, memberLevel, noteRedisSize, oneToOneRedisSize } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        userId: memberInfo.userId,
        memberLevel: memberInfo.depositLevel,
        noteRedisSize: memberInfo.noteRedisSize,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      }
    },
  )

  const {
    totalBettingPer,
    bettingCartList,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bettingCartCountExcludeBonus,
    bettingCartCount,
    selectedBonusFolder,
    activeSportsMarketType,
    sportsBettingInfo,
    bettingDelay,
  } = useSelector(state => {
    const { sportsBetting } = state

    const tempSelectedBonusFolder = sportsBetting.selectedBonusFolder

    let tempBettingCartCount

    if (Object.keys(tempSelectedBonusFolder).length === 0) {
      tempBettingCartCount = sportsBetting.bettingCartList.length
    } else {
      tempBettingCartCount = sportsBetting.bettingCartList.length + 1
    }

    return {
      totalBettingPer: sportsBetting.totalBettingPer,
      bettingCartList: sportsBetting.bettingCartList,
      bettingCartAlertMessage: sportsBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: sportsBetting.bettingCartUpdateMessage,
      bettingCartCountExcludeBonus: sportsBetting.bettingCartList.length,
      bettingCartCount: tempBettingCartCount,
      selectedBonusFolder: sportsBetting.selectedBonusFolder,
      activeSportsMarketType: sportsBetting.activeSportsMarketType,
      sportsBettingInfo: sportsBetting.sportsBettingInfos[sportsBetting.activeSportsMarketType],
      bettingDelay: sportsBetting.bettingDelay,
    }
  })

  const [realSportsBettingInfo, setRealSportsBettingInfo] = useState({})

  useEffect(() => {
    let sportsMaxBettingAmount = 0
    let sportsMaxWinningAmount = 0
    let sportsMaxBettingPer = 0
    let sportsMinBettingAmount = 0
    const sportsMinFolder = sportsBettingInfo?.sportsMinFolder || 0
    const sportsMaxFolder = sportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartCount <= 1) {
      sportsMaxBettingAmount = sportsBettingInfo?.singleFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.singleFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.singleFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.singleFolderSportsMinBettingAmount || 0
    } else if (bettingCartCount === 2) {
      sportsMaxBettingAmount = sportsBettingInfo?.twoFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.twoFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.twoFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.twoFolderSportsMinBettingAmount || 0
    } else {
      sportsMaxBettingAmount = sportsBettingInfo?.multiFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.multiFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.multiFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.multiFolderSportsMinBettingAmount || 0
    }

    setRealSportsBettingInfo({
      sportsMaxBettingAmount,
      sportsMaxWinningAmount,
      sportsMaxBettingPer,
      sportsMinBettingAmount,
      sportsMinFolder,
      sportsMaxFolder,
    })
  }, [bettingCartCount, sportsBettingInfo])

  // betting cart 요소 변경시
  useEffect(() => {
    switch (activeSportsMarketType) {
      case SportMarketType.DOMESTIC:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
        dispatch(setBalanceActiveBetInfo(bettingCartList))
        break
      case SportMarketType.OVERSEAS:
        dispatch(setOverseasActiveBetInfo(bettingCartList))
        break
      case SportMarketType.SPECIAL:
        dispatch(setSpecialActiveBetInfo(bettingCartList))
        break
      case SportMarketType.LIVE:
        dispatch(setLiveActiveBetInfo(bettingCartList))
        break
      default:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
    }
  }, [activeSportsMarketType, bettingCartList, dispatch])

  const [inputBettingMoney, setInputBettingMoney] = useState('0')
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0
    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (inputRealMoney > sportsMaxBettingAmount) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = sportsMaxBettingAmount
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > sportsMaxWinningAmount) {
        resultBettingMoney = Math.floor(sportsMaxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
      }
    }
    if (resultExpectMoney > sportsMaxWinningAmount) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor((sportsMaxWinningAmount || 0) / totalBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, totalBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }

  // 하나 삭제
  const onCloseSportsBettingCart = bettingCartItem => {
    dispatch(removeBettingCartItem(bettingCartItem))
  }

  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(removeAllBettingCartItem())
  }

  const [hideIconCart, setHideIconCart] = useState(false)
  const [bettingCartWrapperHeight, setBettingCartWrapperHeight] = useState('70px')
  const [bettingCartInnerHeight, setBettingCartInnerHeight] = useState('45px')

  const toggleIconCart = () => {
    setHideIconCart(prevShowBettingCart => !prevShowBettingCart)
  }

  useEffect(() => {
    if (hideIconCart) {
      setBettingCartWrapperHeight('430px')
      setBettingCartInnerHeight('405px')
    } else {
      setBettingCartWrapperHeight('70px')
      setBettingCartInnerHeight('45px')
    }
  }, [hideIconCart])

  const mobileMenuRef = useRef()
  const clickMenuOutside = event => {
    if (hideIconCart && !mobileMenuRef.current.contains(event.target)) {
      toggleIconCart()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickMenuOutside)

    return () => {
      document.removeEventListener('mousedown', clickMenuOutside)
    }
  })

  // 배팅 딜레이
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      // Clean up the timeout when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
      dispatch(setBettingDelay(false))
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }, [bettingCartAlertMessage, bettingCartUpdateMessage])

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    const resultLock = bettingCartList.some(
      item =>
        !item.sportVisible ||
        !item.locationVisible ||
        !item.leagueVisible ||
        !item.fixtureVisible ||
        !item.homeVisible ||
        !item.awayVisible ||
        !item.usedMarket ||
        !item.marketVisible ||
        !item.selectedBetInfo.showStatus ||
        item.selectedBetInfo.betStatus !== 'OPEN',
    )

    if (resultLock) {
      alert('배팅 불가인 옵션이 있습니다.')
      setApiFlag(false)
      return
    }

    const sportsMinFolder = realSportsBettingInfo?.sportsMinFolder || 0

    if (bettingCartList.length < sportsMinFolder) {
      alert(`최소 ${sportsMinFolder}폴더부터 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxFolder = realSportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartList.length > sportsMaxFolder) {
      alert(`최대 ${sportsMaxFolder}폴더까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxBettingPer = realSportsBettingInfo?.sportsMaxBettingPer || 0

    if (totalBettingPer > sportsMaxBettingPer) {
      alert(`최대 ${sportsMaxBettingPer}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(inputBettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    const sportsMinBettingAmount = realSportsBettingInfo?.sportsMinBettingAmount || 0

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(sportsMinBettingAmount)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0

    if (Number(inputBettingMoney) > Number(sportsMaxBettingAmount)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(sportsMaxWinningAmount)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      dispatch(setBettingCartLoading(true))

      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        sportMarketType: bettingCartList[0].sportMarketType,
        sportsDetailBettingKeyList: bettingCartList.map(cartItem => {
          return {
            fixtureId: cartItem.fixtureId,
            eventBetKeyList: cartItem.bets.map(betItem => betItem.eventBetKey),
            selectedBetKey: cartItem.selectedBetInfo.eventBetKey,
          }
        }),
        bonusFolder: Object.keys(selectedBonusFolder).length === 0 ? null : selectedBonusFolder,
      }

      if (sportsBettingInfo.bettingDelayTime > 0) {
        dispatch(setBettingDelay(true))
      }

      timeoutRef.current = setTimeout(() => {
        dispatch(setBettingDelay(false))
        dispatch(setBettingCartLoading(true))
        // API 호출
        bettingSportsEventV2(body)
          .then(res => {
            alert('배팅 성공했습니다.')
          })
          .catch(error => {
            const errorCode = error?.response?.data?.errorCode ?? ''

            switch (errorCode) {
              case 'SPORTS-1010':
                alert('마감된 경기가 있어 거절됩니다.')
                break
              case 'SYSTEM-1017':
                alert('보유한 머니가 충분하지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1031':
                alert('다폴 배팅허용기준에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1036':
                alert('최소 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1037':
                alert('최대 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1038':
                alert('최소 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1039':
                alert('최대 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1040':
                alert('최대 당첨액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1041':
                alert('단폴 배팅이 허용되지 않은 마켓입니다.')
                break
              case 'SPORTS-1042':
                alert('배팅 대기시간으로 인해 배팅이 거절됩니다.')
                break
              case 'SPORTS-1045':
                alert('배당 변경으로 인해 배팅이 거절됩니다.')
                break
              default:
                alert('배팅 실패했습니다. 잠시후 다시 시도해주세요.')
            }

            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
          })
          .finally(() => {
            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
            onClickResetMoneyHandler()
            dispatch(removeAllBettingCartItem())
            dispatch(fetchMemberInfoAction())
          })
      }, (sportsBettingInfo?.bettingDelayTime ?? 0) * 1000)
    } else {
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    onClearSportsBettingCart()

    const { pathname } = location

    switch (pathname) {
      case '/mobile/sports/overseas':
        dispatch(setActiveSportsMarketType(SportMarketType.OVERSEAS))
        break
      case '/mobile/sports/special':
        dispatch(setActiveSportsMarketType(SportMarketType.SPECIAL))
        break
      case '/mobile/sports/live':
        dispatch(setActiveSportsMarketType(SportMarketType.LIVE))
        break
      case '/mobile/sports/domestic':
      case '/mobile/sports/balance':
      default:
        dispatch(setActiveSportsMarketType(SportMarketType.DOMESTIC))
        break
    }
  }, [location])

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  const getPriceBox = item => {
    // 정규식 패턴: "0-0" 형태 검사
    const pattern = /^(\d+)-(\d+)$/
    const match = item.selectedBetInfo?.baseLineScore?.match(pattern)

    // 핸디 일때만
    if (item.marketFamilyType === 11 && match) {
      const homeScore = Number.isNaN(Number(match[1])) ? 0 : Number(match[1])
      const awayScore = Number.isNaN(Number(match[2])) ? 0 : Number(match[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = item.selectedBetInfo.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (item.selectedBetInfo.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {item.selectedBetInfo?.line ? (
            <span id="pw0" style={{ color: 'white', fontSize: '12px' }}>
              {item.marketName}({finalLine.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
            </span>
          ) : (
            <span id="pw0" style={{ color: 'white', fontSize: '12px' }}>
              {item.marketName} - {item.selectedBetInfo.betNameKr}
            </span>
          )}
        </>
      )
    }

    return (
      <>
        {item.selectedBetInfo?.line ? (
          <span id="pw0" style={{ color: 'white', fontSize: '12px' }}>
            {item.marketName}({item.selectedBetInfo.lineNum.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
          </span>
        ) : (
          <span id="pw0" style={{ color: 'white', fontSize: '12px' }}>
            {item.marketName} - {item.selectedBetInfo.betNameKr}
          </span>
        )}
      </>
    )
  }

  return (
    <>
      {bettingDelay && <CustomBettingAlert bettingDelayTime={sportsBettingInfo?.bettingDelayTime ?? 0} />}

      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}
      <BettingCartWrapper id="footer_cart" ht={bettingCartWrapperHeight} ref={mobileMenuRef}>
        <div id="cart_btn1">
          {hideIconCart ? (
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/common/fct2.png`}
              onClick={() => toggleIconCart()}
              id="ftbtnimg"
              alt=""
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/common/fct1.png`}
              onClick={() => toggleIconCart()}
              id="ftbtnimg"
              alt=""
            />
          )}
        </div>

        <BettingCartInnerWrapper id="ftcartwrap" ht={bettingCartInnerHeight}>
          <BettingCartListBox id="betting_result" show={hideIconCart}>
            {bettingCartList.map(item => {
              const isLock =
                !item.sportVisible ||
                !item.locationVisible ||
                !item.leagueVisible ||
                !item.fixtureVisible ||
                !item.homeVisible ||
                !item.awayVisible ||
                !item.usedMarket ||
                !item.marketVisible ||
                !item.selectedBetInfo.showStatus ||
                item.selectedBetInfo.betStatus !== 'OPEN'

              return (
                <div id="bet_item_0">
                  <div style={{ height: '1px' }} />
                  <div className="bet_item" style={{ position: 'relative' }}>
                    {isLock && (
                      <BettingWhatWrapOverlay>
                        <BettingWhatLockIcon />
                      </BettingWhatWrapOverlay>
                    )}
                    <table cellPadding="0" cellSpacing="0" border="0" width="100%" align="center">
                      <tbody>
                        <tr>
                          <td style={{ lineHeight: '9px' }}>
                            <span className="team_on long" id="phn0">
                              {item.homeName} vs {item.awayName}
                            </span>
                            <br />
                            <span className="team" id="pan0">
                              {item.marketName}
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <a
                              onClick={() => {
                                onCloseSportsBettingCart(item)
                              }}
                            >
                              {getPriceBox(item)}
                              <font
                                style={{ color: '#f8b411', fontWeight: '700', fontSize: '12px', marginLeft: '5px' }}
                              >
                                (<span id="pr0">{item.selectedBetInfo.priceNum}</span>)
                              </font>
                              <img src={`${process.env.PUBLIC_URL}/img/sports/common/footer_cart_btn5.png`} alt="" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}

            {Object.keys(selectedBonusFolder).length !== 0 && (
              <div className="bet_item_0">
                <div style={{ height: '1px' }} />
                <div className="bet_item">
                  <table cellPadding="0" cellSpacing="0" border="0" width="100%" align="center">
                    <tbody>
                      <tr>
                        <td style={{ lineHeight: '9px' }}>
                          <span className="team_on" id="phn0">
                            보너스 배당
                          </span>
                          <br />
                          <span className="team" id="pan0">
                            {selectedBonusFolder.bonusFolderNum}폴더 이상
                          </span>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <a
                            onClick={() => {
                              dispatch(setSelectedBonusFolder({}))
                            }}
                          >
                            <span id="pw0" style={{ color: 'white', fontSize: '12px', wordBreak: 'keep-all' }} />
                            <font style={{ color: '#f8b411', fontWeight: '700', fontSize: '12px', marginLeft: '5px' }}>
                              (<span id="pr0">{selectedBonusFolder.bonusFolderPer}</span>)
                            </font>
                            <img src={`${process.env.PUBLIC_URL}/img/sports/common/footer_cart_btn5.png`} alt="" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </BettingCartListBox>

          <div id="ftcartwrap22">
            <div id="cart_info">
              <div>
                게임 :{' '}
                <b>
                  <span id="bet_cnt">{bettingCartCount}</span>
                </b>{' '}
                폴더
              </div>
              <div>
                배당 : <span id="total_rate">{totalBettingPer || 0}</span>
              </div>
            </div>

            <div id="cart_info2">
              <ul>
                <li style={{ marginRight: '2px' }}>
                  <a
                    onClick={() => {
                      onSubmit()
                    }}
                  >
                    <font className="bt_type1" style={{ width: '60px', padding: '5px 3px', fontWeight: '700' }}>
                      배팅하기
                    </font>
                  </a>
                </li>
                <li style={{ marginRight: '1px' }}>
                  <a
                    onClick={() => {
                      onClickMoneyHandler('')
                    }}
                  >
                    <font
                      className="bt_type3"
                      style={{ width: '30px', padding: '5px 3px', fontWeight: '700', color: 'white' }}
                    >
                      맥스
                    </font>
                  </a>
                </li>
                <li style={{ marginRight: '5px', color: '#fff' }}>
                  <input
                    name="BettingMoney"
                    id="BettingMoney"
                    value={inputBettingMoney}
                    style={{ border: 'solid 1px #fff !important' }}
                    onChange={e => {
                      const value = e.target.value.trim()
                      const numbersOnly = value.match(/\d+/g)?.join('')

                      if (!numbersOnly) {
                        setInputBettingMoney('0')
                      } else {
                        setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                      }
                    }}
                  />
                  <span style={{ color: '#fff' }}>&nbsp;원</span>
                </li>
              </ul>
            </div>
          </div>

          <div id="betting_quota_money_wrap">
            적중 예상금액 :{' '}
            <b>
              <font id="result_money">{expectMoney?.toString().replace(commonReg2, ',') || 0}</font>
            </b>
            &nbsp;원
          </div>
        </BettingCartInnerWrapper>
      </BettingCartWrapper>
    </>
  )
}

export default MobileSportsBettingCartSidebar

const slideInFromRight = keyframes`
  from {
    transform: translateY(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const BettingCartWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  animation: ${slideInFromRight} 0.5s ease-out;
  height: ${props => props.ht} !important;

  z-index: 301;
`

const BettingCartInnerWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  animation: ${slideInFromRight} 0.5s ease-out;
  height: ${props => props.ht} !important;
  overflow-y: auto;
`

const BettingCartListBox = styled.div`
  min-height: 150px;
  display: ${props => (props.show ? 'block' : 'none')};
`

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 302;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`
