import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useRef, useState } from 'react'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyDeposit,
  searchDepositRule,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
} from '../../api/memberManage/memberManageApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstDateTime } from '../../utils/dateTime'
import { MemberDepositStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { HermesMainBorder } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import DepositDeleteRadio from './DepositDeleteRadio'
import { createOnetoOne } from '../../api/customCenter/customCenterApi'

const MoneyDepositForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  /**
   *  회원 입금내역 조회
   */

  const [checkRadio, setCheckRadio] = useState([])

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyDeposit = async () => {
    setLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyDeposit()
  }, [historyParams])

  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
    setScrollPosition(window.scrollY) // Store the current scroll position

    // if (sportsBettingHistoryWrapRef.current) {
    //   sportsBettingHistoryWrapRef.current.scrollTop = 10
    // }

    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  useEffect(() => {
    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = scrollPosition // Set scroll position back after page change
    }
  }, [page, scrollPosition])

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')

  useEffect(() => {
    setLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setDepositMaxAmount(res.data.depositMaxAmount)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const [depositRuleContent, setDepositRuleContent] = useState('')
  const [isShowDepositRuleContent, setIsShowDepositRuleContent] = useState(false)

  const [depositBonusList, setDepositBonusList] = useState([])
  const [isShowDepositBonusMainSelectDrop, setIsShowDepositBonusMainSelectDrop] = useState(false)

  // 선택한 보너스 타입
  const [selectedBonusType, setSelectedBonusType] = useState(null)

  const onClickDepositBonusTypeHandler = key => {
    const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)

    if (data) {
      setSelectedBonusType({
        moneyDepositBonusKey: data.moneyDepositBonusKey,
        title: data.title,
        bonusType: data.bonusType,
      })
    }
    setIsShowDepositBonusMainSelectDrop(false)
  }

  const getDepositBonusImageUrl = type => {
    // hover 때문에 확장자 뺌
    switch (type) {
      case 'BONUS_NONE':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports_etc`
      case 'BONUS_SPORTS':
      case 'BONUS_SPORTS_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports`
      case 'BONUS_CASINO':
      case 'BONUS_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_casino`
      case 'BONUS_SLOT_CASINO':
      case 'BONUS_SLOT_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_slot`
      case 'BONUS_MINI_GAME':
      case 'BONUS_MINI_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_mini`
      case 'BONUS_PLUS':
      case 'BONUS_PLUS_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_all`
      default:
        return ''
    }
  }

  const getSelectedBonusType = () => {
    switch (selectedBonusType?.bonusType) {
      case 'BONUS_NONE':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      default:
        return <span>입금 보너스 선택하기</span>
    }
  }

  useEffect(() => {
    searchDepositRule().then(res => {
      setDepositRuleContent(res.data.depositRule)
      setDepositBonusList(res.data.depositBonusList)
    })
  }, [])

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const [bonusType, setBonusType] = useState('BONUS_SPORTS')

  const onSubmit = e => {
    if (loading) return

    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (!selectedBonusType) {
      alert('보너스 선택을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    setLoading(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
      bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
    }
    createUserMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.')
        setSelectedBonusType(null)
        fetchSearchMemberMoneyDeposit()
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setLoading(false)
        fetchSearchMemberMoneyDeposit()
      })
  }

  const onDeleteHandler = () => {
    if (loading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '⚡ 자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        navigate('/one-to-one')
        setQuestionLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }
  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <table className="tb_notice_box">
              <tbody>
                <tr>
                  <td className="td_notice_td">
                    <div style={{ margin: '0px', padding: '0px' }}>
                      <div style={{ margin: '0px', padding: '0px' }}>
                        {ReactHtmlParser(depositRuleContent, { transform: transformFn })}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="height_50" />

            {/* 신청 */}
            <div>
              <table className="tb_solid_table">
                <tbody>
                  <tr>
                    <td className="title">입금계좌</td>
                    <td className="body">
                      <a
                        style={{ width: '120px' }}
                        className="normal_button"
                        onClick={() => {
                          onQuestionAccountHandler()
                        }}
                      >
                        자동 계좌문의
                      </a>
                      &nbsp; ※ 입금전 필히 계좌문의 해주시기 바랍니다.
                    </td>
                  </tr>
                  <tr>
                    <td className="title">보너스 선택</td>
                    <td className="body" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                      <DepositBonusSelectWrap>
                        <DepositBonusSelectInnerWrap>
                          <DepositBonusMainSelectWrap
                            onClick={() => {
                              setIsShowDepositBonusMainSelectDrop(prev => !prev)
                            }}
                          >
                            {getSelectedBonusType()}
                            <CustomMdKeyboardArrowDown />
                          </DepositBonusMainSelectWrap>
                          {isShowDepositBonusMainSelectDrop && (
                            <DepositBonusMainSelectDropWrap>
                              {/* 아래부터 반복 */}
                              {depositBonusList.map(item => (
                                <DepositBonusMainSelectDropItemWrap>
                                  <DepositBonusMainSelectDropItemBox
                                    url={getDepositBonusImageUrl(item.bonusType)}
                                    onClick={() => {
                                      onClickDepositBonusTypeHandler(item.moneyDepositBonusKey)
                                    }}
                                  >
                                    <DepositBonusMainSelectDropItemImg />
                                    <DepositBonusMainSelectDropItemTextBox sc={item.content}>
                                      <DepositBonusMainSelectDropItemTextContent>
                                        {item.title}
                                      </DepositBonusMainSelectDropItemTextContent>
                                      {item.content && (
                                        <DepositBonusMainSelectDropItemTextSubContent>
                                          {item.content}
                                        </DepositBonusMainSelectDropItemTextSubContent>
                                      )}
                                    </DepositBonusMainSelectDropItemTextBox>
                                  </DepositBonusMainSelectDropItemBox>
                                </DepositBonusMainSelectDropItemWrap>
                              ))}
                            </DepositBonusMainSelectDropWrap>
                          )}
                        </DepositBonusSelectInnerWrap>
                      </DepositBonusSelectWrap>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">충전금액</td>
                    <td className="body">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                name="money_box"
                                type="text"
                                className="input_money_box"
                                id="money_box"
                                style={{ width: '130px', paddingRight: '5px' }}
                                value={applyMoney}
                                readOnly="readonly"
                              />
                            </td>
                            <td className="td_buttons">
                              <span style={{ color: 'white' }}>원</span>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('10000')
                                }}
                              >
                                1만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('50000')
                                }}
                              >
                                5만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('100000')
                                }}
                              >
                                10만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('500000')
                                }}
                              >
                                50만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClickMoneyHandler('1000000')
                                }}
                              >
                                100만원
                              </a>
                            </td>
                            <td className="td_buttons">
                              <a
                                className="btMoney"
                                style={{ width: '66px' }}
                                onClick={() => {
                                  onClearClickHandler()
                                }}
                              >
                                초기화
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="div_button">
              <div style={{ height: '30px' }} />
              <a className="big_button" style={{ width: '200px' }} onClick={onSubmit}>
                충전신청
              </a>
            </div>

            {/* 신청 내역 */}
            <div className="div_list" style={{ marginBottom: '10px' }} ref={sportsBettingHistoryWrapRef}>
              <div style={{ display: 'flex', gap: '5px', margin: '0 0 5px 0', float: 'right' }}>
                <a
                  className="btMoney"
                  style={{ width: '66px' }}
                  onClick={() => {
                    setCheckRadio(content.map(value => value.moneyDepositKey))
                  }}
                >
                  전체선택
                </a>
                <a
                  className="btMoney"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onDeleteHandler()
                  }}
                >
                  삭제하기
                </a>
              </div>
              <table style={{ textAlign: 'center' }}>
                <colgroup>
                  <col style={{ width: '9%' }} />
                  <col style={{ width: '17%' }} />
                  <col style={{ width: '20.5%' }} />
                  <col style={{ width: '20.5%' }} />
                  <col style={{ width: '19%' }} />
                  <col style={{ width: '14%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>삭제</th>
                    <th>아이디</th>
                    <th>신청액</th>
                    <th>지급액</th>
                    <th>처리일자</th>
                    <th>처리상태</th>
                  </tr>
                </thead>
                <tbody>
                  {totalElement > 0 ? (
                    <>
                      {content.map(item => {
                        return (
                          <tr>
                            <td>
                              <DepositDeleteRadio
                                rowInfo={{
                                  moneyDepositKey: item.moneyDepositKey,
                                }}
                                setCheckRadio={setCheckRadio}
                                checkRadio={checkRadio}
                              />
                            </td>
                            <td>{item.memberId}</td>
                            <td>{item.applyMoney?.toString().replace(commonReg2, ',')}</td>
                            <td>{item.paymentMoney?.toString().replace(commonReg2, ',')}</td>
                            <td>{convertToKstDateTime(item.executeDate)}</td>
                            <td>{MemberDepositStatusEnums[item.status]}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6">최근 입금요청 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalElement > 0 && (
              <>
                <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MoneyDepositForm

const DepositBonusSelectWrap = styled.div`
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #111111;
  font-size: 16px;
  word-break: break-all;
`

const DepositBonusSelectInnerWrap = styled.div`
  width: 100%;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectWrap = styled.div`
  width: 100%;
  background: linear-gradient(#fff, #f5fcff);
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  padding: 10px;
  box-sizing: border-box;
  display: block;

  span {
    cursor: pointer;
  }
`

const CustomMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  color: #111111;
  width: auto;
  height: 20px;
  float: right;
  cursor: pointer;
`

const DepositBonusMainSelectDropWrap = styled.div`
  background: linear-gradient(#fff, #f5fcff);
  position: absolute;
  width: 100%;
  z-index: 1000;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemWrap = styled.div`
  padding: 3px;
  box-sizing: border-box;
  position: relative;
  display: block;
`

const DepositBonusMainSelectDropItemImg = styled.div`
  background-position: 50%;
  background-size: 100% 100%;
  width: 45px;
  height: 45px;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemBox = styled.div`
  color: #111;
  background: linear-gradient(#f5fcff, #fff);
  border: 1px solid #f5b32c;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${DepositBonusMainSelectDropItemImg} {
    background-image: ${props => props.url && `url(${props.url}.png)`};
  }

  &:hover {
    background-color: ${HermesMainBorder};

    ${DepositBonusMainSelectDropItemImg} {
      background-image: ${props => props.url && `url(${props.url}_hover.png)`};
    }
  }
`

const DepositBonusMainSelectDropItemTextBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.sc ? 'column' : 'row')};
  float: left;
  padding-left: 15px;
  height: 45px;
  box-sizing: border-box;
`

const DepositBonusMainSelectDropItemTextContent = styled.div`
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: block;
`
const DepositBonusMainSelectDropItemTextSubContent = styled.div`
  width: 100%;
  text-align: left;
  font-size: 13px;
  margin-top: 5px;
  box-sizing: border-box;
  position: relative;
  display: block;
`
