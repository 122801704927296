import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CustomAlert from '../../containers/CustomAlert'
import isLogin from '../../utils/enums/authority'
import MobileSignInPopup from '../MobileLayout/MobileSignIn'
import { searchPartnerUrl } from '../../api/authManage/authManageApi'
import { decodeAccessToken } from '../../utils/token'

const MobileMainGameMenu = () => {
  const navigate = useNavigate()

  const { noteRedisSize, oneToOneRedisSize, attendanceUsing } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      attendanceUsing: memberInfo.attendanceUsing,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })

    navigate(link)
  }

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl()
      .then(res => {
        const { partnerUrl } = res.data

        if (partnerUrl.includes('localhost')) {
          window.open(`http://${partnerUrl}`)
        } else {
          window.open(`https://${partnerUrl}`)
        }
      })
      .catch(error => {})
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <MobileSignInPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <>
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#24247e' }}>
            &nbsp;카지노메뉴
          </div>
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/live-casino')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_banner55.png`}
                    style={{ borderRadius: '4px' }}
                    width="60"
                  />
                  <br />
                  모든Live
                  <br />
                  카지노
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/slot-casino')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_banner66.png`}
                    style={{ borderRadius: '4px' }}
                    width="60"
                  />
                  <br />
                  Online
                  <br />
                  슬롯게임
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: '5px', backgroundColor: '#fff' }} />

        <div className="div_main_button2">
          <div style={{ height: '3px' }} />

          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#8a2b63' }}>
            &nbsp;미니게임
          </div>
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mini-game/ntry/eos1m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_ntry_logo.png`}
                    height="45"
                    width="45"
                  />
                  <br />
                  엔트리
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mini-game/boscore/stat1m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_boscore_logo.png`}
                    height="45"
                    width="45"
                  />
                  <br />
                  보스코어
                </td>
                <td className="td_bt_empty" />
                {/* <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mini-game/named/n/powerball3m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_named_logo.png`}
                    height="45"
                    width="45"
                  />
                  <br />
                  네임드
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mini-game/sureman/roulette1m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_sureman_logo.png`}
                    height="45"
                    width="45"
                  />
                  <br />
                  슈어맨
                </td>
                <td className="td_bt_empty" /> */}
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mini-game/gopick/raccoon')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_gopick_logo.png`}
                    height="45"
                    width="45"
                  />
                  <br />
                  고픽
                </td>
                <td className="td_bt_empty" />
              </tr>
              <div style={{ height: '5px' }} />
            </tbody>
          </table>
        </div>
        <div style={{ height: '5px', backgroundColor: '#fff' }} />
        {/* 
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#8a2b63' }}>
            &nbsp;가상게임
          </div>
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/virtual/soccer')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_virtualgame.png`}
                    height="45"
                    width="45"
                    style={{ borderRadius: '4px' }}
                  />
                  <br />
                  가상게임
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: '5px', backgroundColor: '#fff' }} /> */}

        <div className="div_main_button2">
          <div style={{ height: '3px' }} />

          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#686868' }}>
            &nbsp;기타메뉴
          </div>
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/notice')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/notice.png`} height="45" />
                  <br />
                  공지사항
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/betting-rule')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/betting_rule.png`} height="45" />
                  <br />
                  이용규정
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/event')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/event.png`} height="45" />
                  <br />
                  이벤트
                </td>
                {attendanceUsing && (
                  <>
                    <td className="td_bt_empty" />
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/attendance')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/stamp.png`} height="45" />
                      <br />
                      출석체크
                    </td>
                  </>
                )}
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/mypage')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/profile.png`} height="45" />
                  <br />
                  회원정보
                </td>
                <td className="td_bt_empty" />
              </tr>
              <div style={{ height: '5px' }} />
              {decodeAccessToken()?.auth === 'PARTNER' && (
                <tr>
                  <td colSpan={11} onClick={onClickPartnerUrlHandler}>
                    <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/profile.png`} height="45" />
                    <br />
                    파트너페이지
                  </td>
                  {/* <td className="td_bt_empty" />
                  <td className="td_bt_empty" />
                  <td className="td_bt_empty" />
                  <td className="td_bt_empty" />
                  <td className="td_bt_empty" />
                  <td className="td_bt_empty" /> */}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ height: '5px', backgroundColor: '#fff' }} />
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#976038' }}>
            &nbsp;빠른메뉴
          </div>

          <div style={{ height: '0px', background: 'rgba(0,0,0,12)' }} />
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/deposit')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/charge.png`} height="45" />
                  <br />
                  충전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/withdrawal')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/exchange.png`} height="45" />
                  <br />
                  환전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/betting-history')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/bet_list.png`} height="45" />
                  <br />
                  배팅내역
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/onetoone')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/contact.png`} height="45" />
                  <br />
                  고객센터
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/note')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/message.png`} height="45" />
                  <br />
                  쪽지함
                </td>
                <td className="td_bt_empty" />
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: '10px', backgroundColor: '#fff' }} />
        <div style={{ height: '1px', backgroundColor: 'rgba(0,0,0,12)' }} />
      </>
    </>
  )
}

export default MobileMainGameMenu
