import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberMoneyWithdrawal } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyWithdrawal,
  searchMinimumAmount,
  searchWithdrawalRule,
  updateMemberMoneyHistoryDelete,
} from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { CustomButtonInfo } from '../../shared/components/MobileMoneyCustomElement'
import { MoneyFormModifyButtomInfo } from '../../shared/components/form/newForm'
import { convertToKstByFormat } from '../../utils/dateTime'
import { BonusTypeEnums, MemberDepositStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
import MobileMoneyWithdrawalListData from '../mobileMyPage/mobileMoneyWithdrawalHistory/MobileMoneyWithdrawalListData'

const MobileMoneyWithdrawalForm = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(true)
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   * 회원 머니출금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyWithdrawal(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyWithdrawal = async () => {
    setLoading(true)
    await searchMemberMoneyWithdrawal(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [moneyWithdrawalListData, setMoneyWithdrawalListData] = useState(
    MobileMoneyWithdrawalListData(content, checkRadio, setCheckRadio),
  )

  useEffect(() => {
    setMoneyWithdrawalListData(MobileMoneyWithdrawalListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(moneyWithdrawalListData.tableRowsData)
  useEffect(() => {
    setData(moneyWithdrawalListData.tableRowsData)
  }, [moneyWithdrawalListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState('0')
  const [withdrawlTerm, setWithdrawalMaxTerm] = useState(0)

  useEffect(() => {
    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setWithdrawalMaxAmount(res.data.withdrawalMaxAmount)
        setWithdrawalMaxTerm(res.data.withdrawlTerm)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])

  // 출금 금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [isShowWithdrawalRuleContent, setIsShowWithdrawalRuleContent] = useState(false)
  const [withdrawalRuleContent, setWithdrawalRuleContent] = useState('')
  const [newTwoFolderRolling, setNewTwoFolderRolling] = useState(0)
  const [newMultiFolderRolling, setNewMultiFolderRolling] = useState(0)
  const [newSingleLiveFolderRolling, setNewSingleLiveFolderRolling] = useState(0)
  const [newMiniGameRolling, setNewMiniGameRolling] = useState(0)
  const [newCasinoRolling, setNewCasinoRolling] = useState(0)
  const [newSlotRolling, setNewSlotRolling] = useState(0)
  const [newVirtualGameRolling, setNewVirtualGameRolling] = useState(0)
  const [newHoldemRolling, setNewHoldemRolling] = useState(0)
  const [firstTwoFolderRolling, setFirstTwoFolderRolling] = useState(0)
  const [firstMultiFolderRolling, setFirstMultiFolderRolling] = useState(0)
  const [firstSingleLiveFolderRolling, setFirstSingleLiveFolderRolling] = useState(0)
  const [firstMiniGameRolling, setFirstMiniGameRolling] = useState(0)
  const [firstCasinoRolling, setFirstCasinoRolling] = useState(0)
  const [firstSlotRolling, setFirstSlotRolling] = useState(0)
  const [firstVirtualGameRolling, setFirstVirtualGameRolling] = useState(0)
  const [firstHoldemRolling, setFirstHoldemRolling] = useState(0)
  const [everyTwoFolderRolling, setEveryTwoFolderRolling] = useState(0)
  const [everyMultiFolderRolling, setEveryMultiFolderRolling] = useState(0)
  const [everySingleLiveFolderRolling, setEverySingleLiveFolderRolling] = useState(0)
  const [everyMiniGameRolling, setEveryMiniGameRolling] = useState(0)
  const [everyCasinoRolling, setEveryCasinoRolling] = useState(0)
  const [everySlotRolling, setEverySlotRolling] = useState(0)
  const [everyVirtualGameRolling, setEveryVirtualGameRolling] = useState(0)
  const [everyHoldemRolling, setEveryHoldemRolling] = useState(0)
  const [twoFolderRollingNotBonus, setTwoFolderRollingNotBonus] = useState(0)
  const [multiFolderRollingNotBonus, setMultiFolderRollingNotBonus] = useState(0)
  const [singleLiveFolderRollingNotBonus, setSingleLiveFolderRollingNotBonus] = useState(0)
  const [miniGameRollingNotBonus, setMiniGameRollingNotBonus] = useState(0)
  const [casinoRollingNotBonus, setCasinoRollingNotBonus] = useState(0)
  const [slotRollingNotBonus, setSlotRollingNotBonus] = useState(0)
  const [virtualGameRollingNotBonus, setVirtualGameRollingNotBonus] = useState(0)
  const [holdemRollingNotBonus, setHoldemRollingNotBonus] = useState(0)

  const [bonusPer, setBonusPer] = useState(0)
  const [bonusType, setBonusType] = useState(null)
  const [totalDepositAmount, setTotalDepositAmount] = useState(0)
  const [withdrawRollingStatus, setWithdrawRollingStatus] = useState(null)
  const [currentRollingRatio, setCurrentRollingRatio] = useState(0)
  const [necessarySportsSingleLiveBettingAmount, setNecessarySportsSingleLiveBettingAmount] = useState(0)
  const [necessarySportsTwoBettingAmount, setNecessarySportsTwoBettingAmount] = useState(0)
  const [necessarySportsMultiBettingAmount, setNecessarySportsMultiBettingAmount] = useState(0)
  const [necessaryVirtualBettingAmount, setNecessaryVirtualBettingAmount] = useState(0)
  const [necessaryMiniGameBettingAmount, setNecessaryMiniGameBettingAmount] = useState(0)
  const [necessaryCasinoBettingAmount, setNecessaryCasinoBettingAmount] = useState(0)
  const [necessarySlotBettingAmount, setNecessarySlotBettingAmount] = useState(0)
  const [necessaryHoldemBettingAmount, setNecessaryHoldemBettingAmount] = useState(0)
  const [withdrawalPassword, setWithdrawalPassword] = useState('')
  useEffect(() => {
    searchWithdrawalRule().then(res => {
      setWithdrawalRuleContent(res.data.withdrawalRuleContent)
      setNewTwoFolderRolling(res.data.newTwoFolderRolling)
      setNewMultiFolderRolling(res.data.newMultiFolderRolling)
      setNewSingleLiveFolderRolling(res.data.newSingleLiveFolderRolling)
      setNewMiniGameRolling(res.data.newMiniGameRolling)
      setNewCasinoRolling(res.data.newCasinoRolling)
      setNewSlotRolling(res.data.newSlotRolling)
      setNewVirtualGameRolling(res.data.newVirtualGameRolling)
      setNewHoldemRolling(res.data.newHoldemRolling)
      setFirstTwoFolderRolling(res.data.firstTwoFolderRolling)
      setFirstMultiFolderRolling(res.data.firstMultiFolderRolling)
      setFirstSingleLiveFolderRolling(res.data.firstSingleLiveFolderRolling)
      setFirstMiniGameRolling(res.data.firstMiniGameRolling)
      setFirstCasinoRolling(res.data.firstCasinoRolling)
      setFirstSlotRolling(res.data.firstSlotRolling)
      setFirstVirtualGameRolling(res.data.firstVirtualGameRolling)
      setFirstHoldemRolling(res.data.firstHoldemRolling)
      setEveryTwoFolderRolling(res.data.everyTwoFolderRolling)
      setEveryMultiFolderRolling(res.data.everyMultiFolderRolling)
      setEverySingleLiveFolderRolling(res.data.everySingleLiveFolderRolling)
      setEveryMiniGameRolling(res.data.everyMiniGameRolling)
      setEveryCasinoRolling(res.data.everyCasinoRolling)
      setEverySlotRolling(res.data.everySlotRolling)
      setEveryVirtualGameRolling(res.data.everyVirtualGameRolling)
      setEveryHoldemRolling(res.data.everyHoldemRolling)
      setTwoFolderRollingNotBonus(res.data.twoFolderRollingNotBonus)
      setMultiFolderRollingNotBonus(res.data.multiFolderRollingNotBonus)
      setSingleLiveFolderRollingNotBonus(res.data.singleLiveFolderRollingNotBonus)
      setMiniGameRollingNotBonus(res.data.miniGameRollingNotBonus)
      setCasinoRollingNotBonus(res.data.casinoRollingNotBonus)
      setSlotRollingNotBonus(res.data.slotRollingNotBonus)
      setVirtualGameRollingNotBonus(res.data.virtualGameRollingNotBonus)
      setHoldemRollingNotBonus(res.data.holdemRollingNotBonus)
      setBonusPer(res.data.withdrawalRollingModel.bonusPer)
      setBonusType(res.data.withdrawalRollingModel.bonusType)
      setTotalDepositAmount(res.data.withdrawalRollingModel.totalDepositAmount)
      setWithdrawRollingStatus(res.data.withdrawalRollingModel.withdrawRollingStatus)
      setCurrentRollingRatio(res.data.withdrawalRollingModel.currentRollingRatio)
      setNecessarySportsSingleLiveBettingAmount(res.data.withdrawalRollingModel.necessarySportsSingleLiveBettingAmount)
      setNecessarySportsTwoBettingAmount(res.data.withdrawalRollingModel.necessarySportsTwoBettingAmount)
      setNecessarySportsMultiBettingAmount(res.data.withdrawalRollingModel.necessarySportsMultiBettingAmount)
      setNecessaryVirtualBettingAmount(res.data.withdrawalRollingModel.necessaryVirtualBettingAmount)
      setNecessaryMiniGameBettingAmount(res.data.withdrawalRollingModel.necessaryMiniGameBettingAmount)
      setNecessaryCasinoBettingAmount(res.data.withdrawalRollingModel.necessaryCasinoBettingAmount)
      setNecessarySlotBettingAmount(res.data.withdrawalRollingModel.necessarySlotBettingAmount)
      setNecessaryHoldemBettingAmount(res.data.withdrawalRollingModel.necessaryHoldemBettingAmount)
    })
  }, [])

  const onSubmit = e => {
    if (apiFlag) return
    if (!withdrawalPassword) {
      alert('출금암호를 입력해주세요.')
      return
    }
    if (withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE') {
      return
    }
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }
    if (Number(checkMoney) > Number(withdrawalMaxAmount)) {
      alert(`최대 ${withdrawalMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 출금 가능합니다.`)
      return
    }

    if (Number(checkMoney) > Number(holdingMoney)) {
      alert('보유머니를 다시 확인해주세요.')
      return
    }
    setApiFlag(true)

    const body = {
      // TODO partnerId 로그인한 사람 ID 넣어주기
      applyMoney: checkMoney,
      withdrawalPassword,
    }

    createUserMoneyWithdrawal(body)
      .then(res => {
        alert('출금신청이 완료되었습니다.')
        setApiFlag(false)
        setApplyMoney(0)
        fetchSearchMemberMoneyWithdrawal()
        dispatch(fetchMemberInfo())
        setApplyMoney('0')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1011':
            alert('출금이 불가능한 시간입니다.')
            break
          case 'ASSET-1005':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          case 'ASSET-1006':
            alert('출금 후 재출금 대기시간은 3시간 입니다.')
            break
          case 'ASSET-1013':
            alert(message)
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiFlag(false)
        setApplyMoney('0')
        dispatch(fetchMemberInfo())
        fetchSearchMemberMoneyWithdrawal()
      })
  }

  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
    setScrollPosition(window.scrollY) // Store the current scroll position

    // if (sportsBettingHistoryWrapRef.current) {
    //   sportsBettingHistoryWrapRef.current.scrollTop = 10
    // }

    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = moneyWithdrawalKey => {
    if (deleteLoading) return
    // if (checkRadio.length === 0) {
    //   alert('삭제 할 목록을 선택해주세요.')
    //   return
    // }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: [moneyWithdrawalKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          // setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          // setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  function formatAmount(amount) {
    if (amount != null) {
      return amount >= 0 ? `${amount.toString().replace(commonReg2, ',')} 원` : '0 원'
    }
    return '0 원'
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="/m/Default.aspx">
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>환전요청</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Exchange Request</strong>
          </span>
        </div>

        <div className="mypage_wrap">
          <div className="charge_box">
            <input type="hidden" name="action_mode" value="new_exchange" />
            <input type="hidden" name="charge_bank" value="국민은행" />
            <input type="hidden" name="charge_banknum" value="" />
            <input type="hidden" name="charge_bankname" value="" />

            <input type="hidden" name="charge_mode" value="mobile" />

            <table align="center" cellPadding="0" cellSpacing="0" border="0" width="100%" className="charge_box_table">
              <tbody>
                <tr>
                  <td className="charge_box_td1">&nbsp;보유금액</td>
                  <td className="charge_box_td2">
                    <input type="hidden" id="my_money" name="my_money" value="1509049" />
                    <span style={{ color: '#000', fontSize: '12px' }}>
                      <b>{holdingMoney?.toString().replace(commonReg2, ',')}</b>
                    </span>{' '}
                    원
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;환전은행</td>
                  <td className="charge_box_td2">
                    <input name="charge_name" type="hidden" id="charge_name" value="" />
                    <span style={{ color: '#959595', fontSize: '12px' }}>
                      <b>(정보보호를 위해 공개하지 않습니다)</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;환전계좌</td>
                  <td className="charge_box_td2">
                    <span style={{ color: '#ffba00', fontSize: '12px' }}>
                      <b>{accountNum}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;예금주</td>
                  <td className="charge_box_td2">
                    <input name="charge_name" type="hidden" id="charge_name" value="" readOnly="readonly" />
                    <span style={{ color: '#959595', fontSize: '12px' }}>
                      <b>(정보보호를 위해 공개하지 않습니다)</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">&nbsp;환전요청</td>
                  <td className="charge_box_td3">
                    <table
                      align="center"
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      width="100%"
                      className="charge_box_money"
                    >
                      <tbody>
                        <tr>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('10000')
                            }}
                          >
                            1만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('30000')
                            }}
                          >
                            3만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('50000')
                            }}
                          >
                            5만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('100000')
                            }}
                          >
                            10만원
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('300000')
                            }}
                          >
                            30만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('500000')
                            }}
                          >
                            50만원
                          </td>
                          <td
                            onClick={() => {
                              onClickMoneyHandler('1000000')
                            }}
                          >
                            100만원
                          </td>
                          <td
                            onClick={() => {
                              onClearClickHandler()
                            }}
                            className="charge_box_money_cancel"
                          >
                            <b>
                              <span style={{ color: '#000' }}>초기화</span>
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">&nbsp;환전요청</td>
                  <td className="charge_box_td2">
                    <input name="charge_type" id="charge_type_money" type="hidden" />
                    <input
                      name="charge_price"
                      type="text"
                      id="charge_price"
                      style={{
                        width: '150px',
                        height: '23px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        textAlign: 'right',
                        paddingRight: '10px',
                      }}
                      value={applyMoney}
                      onChange={e => {
                        setApplyMoney(e.target.value)
                      }}
                      maxLength="10"
                    />
                    &nbsp;<b style={{ color: '#fff', fontSize: '16px' }}>원</b>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">
                    &nbsp;환전
                    <br />
                    &nbsp;비밀번호
                  </td>
                  <td className="charge_box_td2">
                    <input
                      type="password"
                      id="bankiopasswd"
                      name="bankiopasswd"
                      style={{ width: '100px', height: '22px', border: '1px solid #000' }}
                      maxLength="12"
                      value={withdrawalPassword}
                      onChange={e => {
                        setWithdrawalPassword(e.target.value)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">최근 입금액</td>
                  <td className="charge_box_td2">{totalDepositAmount?.toString().replace(commonReg2, ',')}원</td>
                </tr>
                <tr>
                  <td className="charge_box_td1">최근 보너스</td>
                  <td className="charge_box_td2">
                    {BonusTypeEnums[bonusType]} - {bonusPer}%
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1">롤링 진행율</td>
                  <td className="charge_box_td2">
                    <span style={{ color: currentRollingRatio < 100 ? 'red' : 'green' }}>{currentRollingRatio}%</span>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td1" rowSpan={2}>
                    필요 <br /> 배팅금액
                  </td>
                  <td className="charge_box_td2" style={{ padding: '0px', margin: '0px', height: '36px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '0px',
                        height: '36px',
                      }}
                    >
                      <div style={{ display: 'flex', width: '100%', height: '18px' }}>
                        <CustomSpan2>인플레이/단폴더</CustomSpan2>
                        {/* <CustomSpan2>스포츠 두폴더</CustomSpan2> */}
                        <CustomSpan2>스포츠 두폴이상</CustomSpan2>
                      </div>
                      <div style={{ display: 'flex', height: '18px' }}>
                        <CustomSpan3>{formatAmount(necessarySportsSingleLiveBettingAmount)}</CustomSpan3>
                        {/* <CustomSpan3>{formatAmount(necessarySportsTwoBettingAmount)}</CustomSpan3> */}
                        <CustomSpan3>{formatAmount(necessarySportsMultiBettingAmount)}</CustomSpan3>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="charge_box_td2" style={{ padding: '0px', margin: '0px', height: '36px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '0px',
                        height: '36px',
                      }}
                    >
                      <div style={{ display: 'flex', width: '100%', height: '18px' }}>
                        <CustomSpan2>미니게임</CustomSpan2>
                        <CustomSpan2>카지노</CustomSpan2>
                        <CustomSpan2 style={{ borderRight: 'none' }}>슬롯</CustomSpan2>
                      </div>
                      <div style={{ display: 'flex', height: '18px' }}>
                        <CustomSpan3>{formatAmount(necessaryMiniGameBettingAmount)}</CustomSpan3>
                        <CustomSpan3>{formatAmount(necessaryCasinoBettingAmount)}</CustomSpan3>
                        <CustomSpan3 style={{ borderRight: 'none' }}>
                          {formatAmount(necessarySlotBettingAmount)}
                        </CustomSpan3>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn_wrap">
            <a onClick={onSubmit}>
              <font
                className="bt_type1"
                style={{ width: '200px', height: '30px', lineHeight: '30px', fontWeight: '100' }}
              >
                환전신청
              </font>
            </a>
          </div>
          <div style={{ height: '10px' }} />
          <div className="mypage_wrap">
            <table className="mypage_list_table">
              <tbody>
                {totalElement > 0 ? (
                  <>
                    {content.map(item => {
                      return (
                        <tr>
                          <td>
                            {convertToKstByFormat(item.applyDate, 'YY.MM.DD')} [
                            {convertToKstByFormat(item.applyDate, 'HH:mm')}]&nbsp;
                            <b>{item.applyMoney?.toString().replace(commonReg2, ',')} 원</b>&nbsp;&nbsp;
                            {MemberDepositStatusEnums[item.status]}
                          </td>
                          <td style={{ width: '23px', textAlign: 'center' }}>
                            {item.status !== 'APPLY' && (
                              <a
                                onClick={() => {
                                  onDeleteHandler(item.moneyWithdrawalKey)
                                }}
                                // href="javascript:delete_charge_list('1379610')"
                              >
                                <img
                                  style={{ height: '20px', marginTop: '1px' }}
                                  src={`${process.env.PUBLIC_URL}/img/popup/x_button.png`}
                                  alt=""
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan="2">최근 출금요청 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
        </div>
      </div>
    </>
  )
}

export default MobileMoneyWithdrawalForm

const CustomSpan2 = styled.span`
  width: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #9a9a9a;
  border-right: solid 1px #9a9a9a;
  color: #000;
  font-weight: 500;
  background: #dddddd;
`

const CustomSpan3 = styled.span`
  width: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-right: solid 1px #9a9a9a;
  color: #000;
`

const PageOverlayWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  // align-items: center;
  text-align: center;
`

const PageOverlayImg = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
`

const PageOverlayContent = styled.div`
  color: #fff;
  font-size: 14px;
`

const PageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 100px;
`

// endregion
const RollingTopWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-right: 20px;
  // margin-top: 20px;
  padding-top: 20px;
`
const RollingWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 3px;
  justify-content: center;
  gap: 20px;
  // background: #1c1f21;
`

const RollingHeaderWrap = styled.div`
  // height: 50px;
  width: 100%;
  display: flex;
  padding-left: 7px;
  align-items: flex-start;
`

const RollingHeaderInfo1 = styled.div`
  font-size: 15px;
  color: #fff;
`

const RollingHeaderInfo2 = styled.div`
  font-size: 15px;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const RollingContentWrap1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  align-items: flex-start;
`

const RollingContentBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

const RollingCompleteImg = styled.img`
  width: 36px;
  height: 36px;
`

const RollingContentTitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const RollingContentTitle1 = styled.div`
  font-size: 13px;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const RollingContentTitle2 = styled.div`
  font-size: 12px;
`

const RollingContentWrap2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 35px;
  background-color: #000;
  position: relative;
  border-radius: 7px;
`

const FilledBar = styled.div`
  height: 100%;
  width: ${props => (props.percentage >= 100 ? '100%' : `${props.percentage}%`)};
  background-color: ${props => (props.percentage >= 100 ? 'green' : `${HermesMainBorder}`)};
  border-radius: 7px;
`

const PercentageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 가로, 세로 중앙 정렬 */
  color: #fff;
  font-size: 13px;
  font-weight: 600;
`

const RollingContentWrap3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RollingContentTitle3 = styled.div`
  font-size: 12px;
  color: #fff;
`

const RollingContentSportWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`

const RollingContentSportTypeBox = styled.div`
  width: 33.3%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  background: #2f3438;
  padding-top: 5px;
`

const RollingContentSportTypeImg = styled.img`
  width: 33px;
  height: 27px;
`
const RollingContentSportTypeTitle = styled.div`
  font-size: 11px;
  color: #fff;
  font-weight: 500;
`

const RollingContentSportTypeMoney = styled.div`
  font-size: 12px;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

// endregion
export const MobileListButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  padding-left: 5px;
  gap: 5px;
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100vw;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
`

const ContentRowThirdWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px; /* 혹은 다른 높이 값으로 설정 */
`

const ContentRowSecondWrap = styled.div`
  margin-top: 30px;
  gap: 5px;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  span {
    color: #fff;
  }
`

const ContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    font-family: 'Noto Sans', sans-serif;
    height: 62px;
    width: 80px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`
const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-size: 12px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`
const ContentRowWrap2 = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

const ContentRowWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  // font-weight: 300;
`

const ContentInfo = styled.div`
  width: 100%;
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  font-size: 14px;
  font-weight: 700;
`
const MoneyButton = styled.button`
  width: 90%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

export const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
`

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Noto Sans', sans-serif;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`

const MoneyWithdrawalWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;
`

const MoneyWithdrawalHeaderWrap = styled.div`
  height: 130px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  // border: 1px solid red;
  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyWithdrawalCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyWithdrawalBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

export const MoneyWithdrawalInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MoneyWithdrawalRuleWrap = styled.div`
  background: #141517 !important;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  font-family: 'Noto Sans', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 13px;
`

const MoneyWithdrawalRuleContentWrap = styled.div`
  word-break: keep-all;
`

const MoneyWithdrawalRuleImgWrap = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 160px;
    height: auto;
    margin-top: 80px;
  }
`

const MoneyWithdrawalRuleInfo1 = styled.div`
  color: #878e99;
  word-break: keep-all;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  display: block;
  position: relative;
`

const MoneyWithdrawalRuleInfo2 = styled.div`
  text-align: center;
  margin-top: 30px;
  box-sizing: border-box;
  display: block;
  position: relative;

  span {
    border-radius: 5px;
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 10px 20px 10px 20px;
    text-align: center;
  }
`

const TableWrap = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow-x: auto;

  table {
    width: 100%;
    height: 100%;
    background-color: transparent;
    font-size: 12px;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    margin-inline-start: auto;
    margin-inline-end: auto;
    display: table;
    text-indent: initial;
    border-color: gray;
    color: #fff;
    word-break: keep-all;

    font-family: 'Noto Sans', sans-serif;
  }

  tbody {
    box-sizing: border-box;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: baseline;
    border-color: inherit;
    font-size: 12px;
  }
`

const TableFirstTd = styled.td`
  background: #b5b5b7;
  border: 1px solid #b5b5b7;
  color: #fff;
  height: 22px;
  font-size: 11px;
  text-align: center !important;
  padding: 0;
  box-sizing: border-box;
  display: table-cell;
  vertical-align: inherit;
`

const TableHaderTd = styled.td`
  text-align: center !important;
  font-size: 11px !important;
  padding: 0 !important;
  padding-top: 3px !important;
  box-sizing: border-box;
`

const TableBodyTd = styled.td`
  background: #191919;
  border: 1px solid #fff;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 40px;
  font-size: 14px;
  text-align: center !important;
  padding: 0 !important;
  vertical-align: middle;
  font-weight: 600;
`
