import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteVirtualGameBettingHistory, searchVirtualGameBettingHistory } from '../../api/game/virtualGameApi'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  FixtureBetResult,
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualGameResultStatusEnums,
  VirtualGameSportType,
  VirtualResultSettlementTypeEnums,
} from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainBorder, HermesMainSelect, HermesMainText, KyochonMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import VirtualGameBettingHistoryRadio from './VirtualGameBettingHistoryRadio'

const VirtualGameBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [virtualGameBettingHistoryParams, setVirtualGameBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [virtualGameSportType, setVirtualGameSportType] = useState(null)

  // 미니게임 종목 선택
  const onClickVirtualGameTypeHandler = type => {
    setVirtualGameSportType(type)

    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page: 0,
      virtualGameSportType: type || null,
    }))
  }

  const [totalElements, setTotalElements] = useState(0)
  const [virtualGameBettingHistoryContent, setVirtualGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchVirtualGameBettingHistory = async params => {
    await searchVirtualGameBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setVirtualGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
  }, [virtualGameBettingHistoryParams])

  /**
   * 페이징처리
   */
  const virtualGameBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (virtualGameBettingHistoryWrapRef.current) {
      virtualGameBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteVirtualGameBettingHistory({
        virtualBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
    }
  }

  const getFixtureRowInfoCompo = detailItem => {
    switch (detailItem.marketId) {
      case 1:
        // 승무패
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'WIN_DRAW_LOSE_HOME_PICK'}>
              {detailItem.homeName}
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="20" active={detailItem.selectedBetType === 'WIN_DRAW_LOSE_DRAW_PICK'}>
              VS
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'WIN_DRAW_LOSE_AWAY_PICK'}>
              {detailItem.awayName}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 2:
        // 승패
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'WIN_DRAW_LOSE_HOME_PICK'}>
              {detailItem.homeName}
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="20">VS</BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'WIN_DRAW_LOSE_AWAY_PICK'}>
              {detailItem.awayName}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 3:
        // 핸디
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'HANDICAP_HOME_PICK'}>
              {detailItem.homeName}
              <img
                src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
                alt=""
                style={{ width: '12px', height: '12px', marginLeft: '5px' }}
              />
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="20">{detailItem.handicapPoint}</BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'HANDICAP_AWAY_PICK'}>
              <img
                src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
                alt=""
                style={{ width: '12px', height: '12px', marginRight: '5px' }}
              />
              {detailItem.awayName}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 4:
        // 언더오버
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'UNDER_OVER_OVER_PICK'}>
              {detailItem.homeName}
              <img
                src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
                alt=""
                style={{ width: '12px', height: '12px', marginLeft: '5px' }}
              />
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="20">{detailItem.overUnderPoint}</BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'UNDER_OVER_UNDER_PICK'}>
              <img
                src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
                alt=""
                style={{ width: '12px', height: '12px', marginRight: '5px' }}
              />
              {detailItem.awayName}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 6:
        // 더블찬스
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox
              width="33"
              active={detailItem.selectedBetType === 'DOUBLE_CHANCE_HOME_OR_DRAW'}
            >
              홈승 또는 무승부
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox
              width="33"
              active={detailItem.selectedBetType === 'DOUBLE_CHANCE_HOME_OR_AWAY'}
            >
              홈승 또는 원정승
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox
              width="33"
              active={detailItem.selectedBetType === 'DOUBLE_CHANCE_DRAW_OR_AWAY'}
            >
              무승부 또는 원정승
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 7:
        // 총 골수
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="100" active>
              {VirtualGameBettingTypeKr[detailItem.selectedBetType]}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 8:
        // 우승 맞추기
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="100" active>
              <img
                src={`${process.env.PUBLIC_URL}/img/virtual-game/${detailItem.selectedRaceImageUrl}`}
                alt=""
                style={{ width: '15px', height: '15px' }}
              />
              [{detailItem.selectedRaceNumber}] {detailItem.selectedRaceName}{' '}
              {detailItem.selectedRaceRider && `, ${detailItem.selectedRaceRider}`}
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      case 9:
        // 홀짝
        return (
          <BettingHistoryContentItemBoxWrapper>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'RACE_ODD_EVEN_ODD_PICK'}>
              홀
            </BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="20">VS</BettingHistoryContentItemBox>
            <BettingHistoryContentItemBox width="40" active={detailItem.selectedBetType === 'RACE_ODD_EVEN_EVEN_PICK'}>
              짝
            </BettingHistoryContentItemBox>
          </BettingHistoryContentItemBoxWrapper>
        )
      default:
        return ''
    }
  }

  const getFixtureResultCompo = detailItem => {
    switch (detailItem.sportType) {
      case 'VIRTUAL_SOCCER':
        return (
          <BettingHistoryContentItemBoxWrapper>
            {detailItem.betSettlementType === 'NOT_SETTLED' ? (
              <BettingHistoryContentItemBox width="100">-</BettingHistoryContentItemBox>
            ) : (
              <BettingHistoryContentItemBox width="100">
                {detailItem.homeScore} : {detailItem.awayScore}
              </BettingHistoryContentItemBox>
            )}
          </BettingHistoryContentItemBoxWrapper>
        )
      case 'VIRTUAL_BASKETBALL':
        return (
          <BettingHistoryContentItemBoxWrapper>
            {detailItem.betSettlementType === 'NOT_SETTLED' ? (
              <BettingHistoryContentItemBox width="100">-</BettingHistoryContentItemBox>
            ) : (
              <>
                <BettingHistoryContentItemBox width="33">
                  승패: {FixtureBetResult[detailItem.winDrawLoseResult]}
                </BettingHistoryContentItemBox>
                <BettingHistoryContentItemBox width="33">
                  언더/오버: {FixtureBetResult[detailItem.overUnderResult]}
                </BettingHistoryContentItemBox>
                <BettingHistoryContentItemBox width="33">
                  핸디: {FixtureBetResult[detailItem.handicapResult]}
                </BettingHistoryContentItemBox>
              </>
            )}
          </BettingHistoryContentItemBoxWrapper>
        )
      case 'VIRTUAL_BASEBALL':
        return (
          <BettingHistoryContentItemBoxWrapper>
            {detailItem.betSettlementType === 'NOT_SETTLED' ? (
              <BettingHistoryContentItemBox width="100">-</BettingHistoryContentItemBox>
            ) : (
              <BettingHistoryContentItemBox width="100">
                {detailItem.homeScore} : {detailItem.awayScore}
              </BettingHistoryContentItemBox>
            )}
          </BettingHistoryContentItemBoxWrapper>
        )
      case 'VIRTUAL_GREYHOUNDS':
        return (
          <BettingHistoryContentItemBoxWrapper>
            {detailItem.betSettlementType === 'NOT_SETTLED' ? (
              <BettingHistoryContentItemBox width="100">-</BettingHistoryContentItemBox>
            ) : (
              <BettingHistoryContentItemBox width="100">
                {!detailItem.raceFirstName ? (
                  <>-</>
                ) : (
                  <>
                    우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                  </>
                )}
              </BettingHistoryContentItemBox>
            )}
          </BettingHistoryContentItemBoxWrapper>
        )
      case 'VIRTUAL_HORSE':
        return (
          <BettingHistoryContentItemBoxWrapper>
            {detailItem.betSettlementType === 'NOT_SETTLED' ? (
              <BettingHistoryContentItemBox width="100">-</BettingHistoryContentItemBox>
            ) : (
              <BettingHistoryContentItemBox width="100">
                {!detailItem.raceFirstName ? (
                  <>-</>
                ) : (
                  <>
                    우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                  </>
                )}
              </BettingHistoryContentItemBox>
            )}
          </BettingHistoryContentItemBoxWrapper>
        )
      default:
        return ''
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={virtualGameBettingHistoryWrapRef}>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}
      <ListWrap>
        <SubMenuItemListWrap>
          <SubMenuItemBox
            active={!virtualGameSportType}
            onClick={() => {
              onClickVirtualGameTypeHandler(null)
            }}
            className="normal_button"
          >
            전체
          </SubMenuItemBox>
          {Object.entries(VirtualGameSportType).map(([key, value]) => {
            return (
              <SubMenuItemBox
                active={key === virtualGameSportType}
                onClick={() => {
                  onClickVirtualGameTypeHandler(key)
                }}
                className="normal_button"
              >
                {value}
              </SubMenuItemBox>
            )
          })}
        </SubMenuItemListWrap>
        <div style={{ display: 'flex', gap: '5px', marginTop: '20px' }}>
          <a
            className="normal_button"
            style={{ height: '20px', width: '110px' }}
            onClick={() => {
              setCheckRadio(
                virtualGameBettingHistoryContent
                  .filter(value => value.virtualResultStatus !== 'ING')
                  .map(value => value.virtualBettingLogKey),
              )
            }}
          >
            전체선택
          </a>
          <a style={{ height: '20px', width: '110px' }} className="normal_button" onClick={() => onDeleteHandler()}>
            삭제하기
          </a>
        </div>
      </ListWrap>
      {virtualGameBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          가상게임 배팅내역이 없습니다.
        </NoContentDiv>
      ) : (
        <div className="bet_group" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {virtualGameBettingHistoryContent.map((item, index) => (
            <table style={{ textAlign: 'center' }} className="tb_bet_list" key={item.virtualBettingLogKey}>
              <colgroup>
                <col style={{ width: '8%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '24%' }} />
                <col style={{ width: '8%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>경기일시</th>
                  <th>종목</th>
                  <th>리그</th>
                  <th>마켓</th>
                  <th>경기</th>
                  <th>점수</th>
                  <th>결과</th>
                </tr>
              </thead>
              <tbody>
                {item.virtualDetailBettingLogList.map(detail => (
                  <>
                    <tr key={detail.virtualDetailBettingLogKey} style={{}}>
                      <td>{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</td>
                      <td>
                        <BettingHistoryContentItemBox width="100" virtualGameSportType={item.virtualGameSportType}>
                          <img
                            style={{ width: '20px', height: '20px' }}
                            src={`${process.env.PUBLIC_URL}/img/virtual-game/${item.virtualGameSportType}.png`}
                            alt=""
                          />
                          &nbsp;
                          {VirtualGameSportType[item.virtualGameSportType]}
                        </BettingHistoryContentItemBox>
                      </td>

                      <td>{VirtualGameLeagueTypeKr[detail.leagueType]}</td>
                      <td>{detail.marketName}</td>
                      <td>{getFixtureRowInfoCompo(detail)}</td>
                      <td>{getFixtureResultCompo(detail)}</td>
                      <td>
                        <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
                          {VirtualResultSettlementTypeEnums[detail.betSettlementType]}
                        </BettingHistoryContentBoxFooterInfo>
                      </td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td colSpan={8} className="td_result_line" style={{ width: '100%' }}>
                    <BettingHistoryContentBox>
                      <VirtualGameBettingHistoryRadio
                        virtualBettingLogKey={item.virtualBettingLogKey}
                        virtualResultStatus={item.virtualResultStatus}
                        checkRadio={checkRadio}
                        setCheckRadio={setCheckRadio}
                      />
                      <BettingHistoryContentBoxFooterInfo>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 시간 :</span>{' '}
                        {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                      </BettingHistoryContentBoxFooterInfo>
                      <BettingHistoryContentBoxFooterInfo>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 금액 : </span>
                        {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                      </BettingHistoryContentBoxFooterInfo>
                      <BettingHistoryContentBoxFooterInfo>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배당 : </span>
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = item
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder || realReduceBettingPerForCombination

                          if (!reduceBettingValue) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                            <>
                              {Number(reduceBettingValue) < 0 ? (
                                <span>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              ) : (
                                <span>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              )}
                            </>
                          )
                        })()}
                      </BettingHistoryContentBoxFooterInfo>
                      <BettingHistoryContentBoxFooterInfo>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>예상 적중금액 : </span>
                        {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                      </BettingHistoryContentBoxFooterInfo>
                      <BettingHistoryContentBoxFooterInfo>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>적중 금액 : </span>
                        {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                      </BettingHistoryContentBoxFooterInfo>
                      <BettingHistoryContentBoxFooterInfo2 bettingResult={item.virtualResultStatus}>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>결과 : </span>
                        {VirtualGameResultStatusEnums[item.virtualResultStatus]}
                      </BettingHistoryContentBoxFooterInfo2>
                    </BettingHistoryContentBox>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      )}

      {totalElements > 0 && (
        <>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default VirtualGameBettingHistory

const SubMenuItemListWrap = styled.div`
  // border-bottom: 1px solid rgb(41, 41, 41, 0.2);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  height: 75px;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  border-radius: 24px;
  &:hover {
    border: solid 1px #000;
  }

  ${props =>
    props.active &&
    `
    border: solid 1px #000 !important;
    font-weight: 500 !important;
    `}
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: -20px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: -20px;
  margin-bottom: 20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return '#000'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return '#000'
  }
}

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 600;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  font-weight: 600;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  // &:hover {
  //   border: none;
  //   cursor: pointer;
  // }
  font-weight: 700;
`

const getColor3 = virtualGameSportType => {
  switch (true) {
    case virtualGameSportType === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case virtualGameSportType === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case virtualGameSportType === 'VIRTUAL_BASEBALL':
      return '#8f47fc'
    case virtualGameSportType === 'VIRTUAL_HORSE':
      return '#36bd29'
    case virtualGameSportType === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return '#000'
  }
}

const BettingHistoryContentItemBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  // background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  color: ${props => getColor3(props.virtualGameSportType)};

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${KyochonMainText};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 10%;
  position: relative;
  color: red;
  margin-top: 2px;
  margin-left: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 70%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 5px;
  float: left;
  font-weight: 600;
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 29%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;
`
