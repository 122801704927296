import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaArrowRight } from 'react-icons/fa'
import { HiOutlineRefresh } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { BiLock } from 'react-icons/bi'
import { searchGameUrl, searchLiveVendor } from '../../api/game/gameApi'
import { exchangeMoney, withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { ContentWrap } from '../../shared/components/MobileMoneyCustomElement'
import {
  HermesMainBorder,
  HermesMainHover,
  HermesMainHover2,
  HermesMainText,
  KyochonMainText,
} from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import CasinoGameViewPopup from './CasinoGameViewPopup'

const LiveCasino = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { holdingMoney, totalGameMoney } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })

  const [dartShow, setDartShow] = useState(false)
  const onCloseMoneyAlert = () => {
    setDartShow(false)
  }
  useEffect(() => {
    if (totalGameMoney === 0) {
      setDartShow(true)
    }
  }, [totalGameMoney])

  const [liveCasinoVendors, setLiveCasinoVendors] = useState([])

  // 경고창 용 팝업
  const [alertPopupShow, setAlertPopupShow] = useState(false)
  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
  }

  useEffect(() => {
    window.scrollTo(10, 0)

    setLoading(true)
    searchLiveVendor()
      .then(res => {
        setLiveCasinoVendors(res.data.vendors)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const [gameLoading, setGameLoading] = useState(false)

  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const [casinoGameUrl, setCasinoGameUrl] = useState('')

  const notGamePopupVendors = ['BOTA', 'ASIA GAMING LIVE', 'SUPERSPADE']

  const gameStartHandler = game => {
    setGameLoading(true)
    searchGameUrl({
      agencyName: game.agencyName,
      vendorType: game.vendorType,
      gameKey: game.vendorKey,
    })
      .then(res => {
        const { gameUrl } = res.data
        const idx = notGamePopupVendors.findIndex(item => item === game.vendorName)

        if (idx === -1) {
          setCasinoGameUrl(gameUrl)
          setCasinoGameViewPopupShow(true)
        } else {
          window.open(
            `${gameUrl}`,
            '',
            `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
          )
        }
        setGameLoading(false)
      })
      .catch(error => {
        dispatch(fetchMemberInfo())
        setGameLoading(false)
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'GAME-1001':
            alert('이용이 불가능합니다. 관리자에게 문의하세요.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            setAlertPopupShow(true)
        }
      })
  }

  // 지갑 또는 카지노 머니로 이동할 금액
  const [moneyExchangeAmount, setMoneyExchangeAmount] = useState('')

  const onClickMoneyExchangeAmount = value => {
    setMoneyExchangeAmount(value.replaceAll(',', '').replace(commonReg2, ','))
  }

  const onSubmit = e => {}

  const [apiFlag, setApiFlag] = useState(false)

  const [loadingInfo, setLoadingInfo] = useState('')
  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)
  const onSubmitToGame = e => {
    if (apiFlag) return
    if (moneyExchangeAmount === '' || moneyExchangeAmount === '0') {
      alert('보낼 금액을 입력하세요.')
      return
    }
    if (Number(moneyExchangeAmount?.toString().replaceAll(',', '')) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    setApiFlag(true)
    setMoneyExchangeLoading(true)
    setLoadingInfo(`[지갑]에서 [카지노 머니]로 머니 이동 중입니다.`)

    const body = {
      amount: Number(moneyExchangeAmount.replaceAll(',', '')),
      type: 'TOGAME',
    }

    exchangeMoney(body)
      .then(res => {
        alert(`지갑머니에서 카지노 머니로 ${moneyExchangeAmount}원이 이동되었습니다.`)
        setMoneyExchangeAmount('')
        setApiFlag(false)
        setMoneyExchangeLoading(false)
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'ASSET-1005':
            alert(message)
            break
          case 'MONEY-1007':
            alert(
              '현재 처리중인 입금 신청이 존재합니다.\n마이페이지 - 머니 입금내역 탭에서 상태 확인 후 다시 시도해주세요.',
            )
            break
          case 'MONEY-1008':
            alert(
              '현재 처리중인 출금 신청이 존재합니다.\n마이페이지 - 머니 출금내역 탭에서 상태 확인 후 다시 시도해주세요.',
            )
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          default:
            alert(`카지노 머니로 머니이동이 실패하였습니다. 잠시후 다시 시도해주세요.`)
        }
        setApiFlag(false)
        setMoneyExchangeLoading(false)
      })
  }

  const onSubmitToWallet = e => {
    if (apiFlag) return
    if (moneyExchangeAmount === '0' || moneyExchangeAmount === '') {
      alert('보낼 금액을 입력하세요.')
      return
    }
    if (Number(moneyExchangeAmount?.toString().replaceAll(',', '')) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    setApiFlag(true)
    setMoneyExchangeLoading(true)
    setLoadingInfo(`[카지노 머니]에서 [지갑]으로 머니 이동 중입니다.`)

    const body = {
      amount: Number(moneyExchangeAmount.replaceAll(',', '')),
      type: 'TOWALLET',
    }

    exchangeMoney(body)
      .then(res => {
        alert(`카지노 머니에서 지갑으로 ${moneyExchangeAmount}원이 이동되었습니다.`)
        setMoneyExchangeAmount('')
        setApiFlag(false)
        setMoneyExchangeLoading(false)
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-1009':
            alert(message)
            break
          case 'MONEY-1007':
            alert(
              '현재 처리중인 입금 신청이 존재합니다.\n마이페이지 - 머니 입금내역 탭에서 상태 확인 후 다시 시도해주세요.',
            )
            break
          case 'MONEY-1008':
            alert(
              '현재 처리중인 출금 신청이 존재합니다.\n마이페이지 - 머니 출금내역 탭에서 상태 확인 후 다시 시도해주세요.',
            )
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          default:
            alert(`지갑으로 머니이동이 실패하였습니다. 잠시후 다시 시도해주세요.`)
        }
        setApiFlag(false)
        setMoneyExchangeLoading(false)
      })
  }

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) return
    if (totalGameMoney <= 0) return
    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setMoneyExchangeLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('처리 실패했습니다.')
        }
        setMoneyExchangeLoading(false)
      })
  }

  return (
    <>
      {casinoGameViewPopupShow && (
        <CasinoGameViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
        />
      )}
      {(loading || gameLoading) && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div style={{ textAlign: 'center', margin: '20px', display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '20px', fontWeight: '500' }}>- Live Casinos -</span>
              <span style={{ fontSize: '15px', fontWeight: '400' }}>
                카지노 접속시 자동으로 카지노 보유머니로 이동되는 점 알려드립니다.
              </span>
              <span style={{ fontSize: '15px', fontWeight: '400' }}>
                카지노 게임이 종료되신 후 카지노머니 란 회수버튼 클릭시 보유머니로 이동되는점 알려드립니다.
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              {liveCasinoVendors.map(item => {
                return (
                  <a
                    onClick={() => {
                      if (item.vendorIsInspection) return
                      gameStartHandler(item)
                    }}
                  >
                    <div className="div_casino_item" style={{ position: 'relative' }}>
                      {item.vendorIsInspection && (
                        <CasinoInspectionBannerWrap>
                          <CustomBiLock />
                          <span>점검중</span>
                        </CasinoInspectionBannerWrap>
                      )}
                      <div>
                        <img
                          src={
                            item.vendorImg ||
                            `${process.env.PUBLIC_URL}/img/game/${item.vendorName
                              ?.toString()
                              ?.replace(/(\s*)/g, '')}.png`
                          }
                          alt=""
                        />
                      </div>
                      <div className="div_title">
                        <span style={{ color: '#000', fontWeight: '900', paddingTop: '3px' }}>{item.vendorNameKo}</span>
                      </div>
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveCasino

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  // flex-direction: row !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      background: ${HermesMainText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  span {
    height: 100%;
    margin-left: -1px;
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainText} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 17px;
  height: auto;
  cursor: pointer;
  color: ${HermesMainBorder} !important;
`

const CasinoTitleWrap = styled.div`
  width: 100%;
  background: #1c1f21;
  margin-top: -3px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: left;
  padding-left: 15px;
  height: 54px;
  text-algin: left;
  svg {
    position: absolute;
    right: 8px;
    width: 15px;
    height: 12px;
  }
`

const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`

const ContentRowWrap = styled.div`
  border-bottom: 2px solid #252525;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

const MoneyExchangeBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 0 4px;
`

const MoneyExchangeContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px;
  // border-bottom: 2px solid #252525;
`
const MoneyExchangeFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`
const MoneyExchangeFormGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 4px;
`

// sign up form field
const MoneyExchangeFormField = styled.div`
  width: 300px;
  // width: 30%;
  display: flex;
  flex-direction: column;
`

// sign up form input wrap
const MoneyExchangeFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  height: 40px;

  display: flex;
`

// sign up form input
const MoneyExchangeFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  height: 100%;

  font-style: normal;
  font-weight: 600;
  line-height: 35px;

  color: white;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    &::placeholder {
      color: black;
    }
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// MoneyExchangeFormGroupIcon
const MoneyExchangeFormGroupIcon = styled.div`
  padding: 6px;
  min-width: max-content;
  height: 100%;
  background: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

// 이동하기 버튼 텍스트
const MoneyExchangeButtonInfo = styled.span`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #fff;
`

const CasinoBannerWrap = styled.div`
  width: 100%;
  margin-top: 32px;
  display: block;

  img {
    width: 100%;
  }
`

const CasinoContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const CasinoContentHeaderBox = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`
const CasinoContentHeaderIconBox = styled.div`
  float: left;

  img {
    width: auto;
    height: 24px;
  }
`

const CasinoContentHeaderTitleBox = styled.div`
  float: left;
  margin-left: 12px;
  margin-top: 1px;
  color: #fff;
  font-size: 14px;
`
const CasinoPlayWrap = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 50px;
  display: block;
  opacity: 0; // 초기 상태에서 숨김
  visibility: hidden; // 초기 상태에서 숨김
  position: absolute;
  cursor: pointer;
  transition: opacity 0.5s ease, visibility 1s ease; // opacity와 visibility에 트랜지션 적용
  background: rgba(0, 0, 0, 0.8);
`
const CasinoGameWrap = styled.div`
  width: 100%;
  float: left;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  max-width: 272px; /* 최대 너비를 272px로 설정 */
  max-height: 264px; /* 최대 높이를 210px로 설정 */
  min-width: 180px; /* 최대 너비를 272px로 설정 */
  min-height: 150px; /* 최대 높이를 210px로 설정 */
`

const CasinoContentInnerWrap = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: block;
  position: relative;
  display: flex;
  min-height: 330px;
  flex-wrap: wrap; /* 요소들이 한 줄에 맞게 나열될 수 있도록 설정 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */

  /* 화면 너비가 780px 초과일 때 한 줄에 4개씩 표시 */
  @media (min-width: 780px) {
    & > ${CasinoGameWrap} {
      width: calc(25% - 16px); /* 4개씩 표시 */
    }
  }

  /* 화면 너비가 780px 이하일 때 한 줄에 2개씩 표시 */
  @media (max-width: 780px) {
    & > ${CasinoGameWrap} {
      width: calc(50% - 5px); /* 2개씩 표시 */
    }
  }
`

const CasinoPlayVendorNameWrap = styled.div`
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 25px;
  font-weight: 500;
`

const CasinoPlayButtonWrap = styled.div`
  padding: 8px 36px;
  background: ${HermesMainHover2};
  border-radius: 36px;
  display: inline-block;
  margin-top: 36px;
  cursor: pointer;
  color: #fff;
`

const CasinoGameInnerWrap = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    ${CasinoPlayWrap} {
      opacity: ${props => (props.isCheck ? 0 : 1)};
      visibility: ${props => (props.isCheck ? 'hidden' : 'visible')};
    }
  }

  img {
    max-width: 100%; /* 이미지의 최대 너비를 부모 요소에 맞게 설정 */
    max-height: 100%; /* 이미지의 최대 높이를 부모 요소에 맞게 설정 */
    width: auto; /* 이미지 너비를 자동으로 조절하여 비율 유지 */
    height: auto; /* 이미지 높이를 자동으로 조절하여 비율 유지 */
    max-width: 272px; /* 이미지 최대 너비를 272px로 설정 */
    max-height: 180px; /* 이미지 최대 높이를 180px로 설정 */
    min-width: 167px; /* 이미지 최대 너비를 272px로 설정 */
    min-height: 104px; /* 이미지 최대 높이를 180px로 설정 */
  }
};
`

const CustomBiLock = styled(BiLock)`
  position: absolute;
  flex-direction: column;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 70px;
  height: 70px;
`

const CasinoInspectionBannerWrap = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: #fff;
  background: rgba(17, 19, 20, 0.75);
  box-sizing: border-box;
  display: block;
  z-index: 1000;

  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    font-weight: 700;
    top: 50%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -50%);
  }
`
