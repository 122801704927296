import React, { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HiOutlineRefresh } from 'react-icons/hi'
import { siginOut } from '../../api/authManage/authManageApi'
import { onClickTitleHandler } from '../../redux/modalSlice'
import {
  CustomPopupHeaderDivWrap,
  CustomPopupHeaderWrap,
  MobileTopWrap,
} from '../../shared/components/MoneyCustomElement'
import Portal from '../../shared/components/Portal'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { decodeAccessToken } from '../../utils/token'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomAlert from '../../containers/CustomAlert'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'

const MobileMyInfo = ({ onClose, visible }) => {
  const { holdingMoney, memberTotalGameMoney, userId, memberLevel, noteRedisSize, oneToOneRedisSize } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        userId: memberInfo.userId,
        memberLevel: memberInfo.depositLevel,
        noteRedisSize: memberInfo.noteRedisSize,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      }
    },
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const onClickSignOutHandler = () => {
    siginOut()
      .then(res => {
        close()
        window.location.replace('/')
      })
      .catch(error => {
        close()
        window.location.replace('/')
      })
  }

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/level1.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/level2.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/level3.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/level4.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/level5.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP1.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP2.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP3.png`
      default:
        return ''
    }
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuModalHandler = title => {
    if (title !== 'NOTE' && noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (title !== 'ONETOONE' && noteRedisSize === 0 && oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(onClickTitleHandler(title))
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)
  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) return
    if (memberTotalGameMoney <= 0) return
    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setMoneyExchangeLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('처리 실패했습니다.')
        }
        setMoneyExchangeLoading(false)
      })
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div
          id="hiddenBar"
          style={{
            background: 'rgb(255, 255, 255)',
            boxShadow: 'rgb(223, 225, 229) 0px 7px 6px -5px',
            display: 'none',
          }}
        >
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ height: '36px', width: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      // onClick="showMenuOn()"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a
                    //  href="/m/Default.aspx"
                    >
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onclick="window.location.reload()"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>회원정보</strong>
          </span>{' '}
          <span className="title2">
            <strong>&nbsp;Member Info</strong>
          </span>
        </div>

        <div className="mypage_wrap">
          <div className="charge_box">
            <table align="center" cellPadding="0" cellSpacing="0" border="0" width="100%" className="charge_box_table">
              <tbody>
                <tr>
                  <td className="charge_box_td2" colSpan="2">
                    <div className="guide_text">
                      <ul>
                        <b>회원정보 안내사항</b>
                      </ul>
                      <ul>
                        <li>
                          고객님의 소중한 정보 보호를 위하여
                          <br />
                          가입 이후 회원정보는 고객센터를 통하여서만
                          <br />
                          가능합니다.{' '}
                        </li>
                        <li>
                          기존 노출되던 회원 개인에대한 정보는 <br />
                          더이상 웹 상에서 노출하지 않습니다.{' '}
                        </li>
                        <li>
                          추천인 정보 및 포인트적립 내역은
                          <br />
                          PC버젼에서만 확인 가능합니다.
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;아이디</td>
                  <td className="charge_box_td2">
                    <span style={{ color: '#000', fontSize: '12px' }}>
                      <b>{userId}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="charge_box_td1">&nbsp;닉네임</td>
                  <td className="charge_box_td2">
                    <span style={{ color: '#000', fontSize: '12px' }}>
                      <b>{decodeAccessToken()?.nickName || ''}</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Portal elementId="signup-modal">
        <SignUpModalOverlay visible={visible} />
        <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
          <SignUpModalInner tabIndex="0">
            <SignUpModalHeader>
              <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
              <SignUpModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
            </SignUpModalHeader>
            <SignUpModalContentWrap>
              <MobileTopWrap>
                <CustomPopupHeaderWrap>
                  <LevelLogo src={getLevelIconUrl()} alt="" />
                  <CustomPopupHeaderDivWrap>
                    <span>{decodeAccessToken()?.userId}</span>
                    <span style={{ color: `${HermesMainText}` }}>{decodeAccessToken()?.nickName}</span>
                  </CustomPopupHeaderDivWrap>
                </CustomPopupHeaderWrap>
                <ContentWrap>
                  <ContentRowWrap>
                    <ContentRowSmall>
                      <div style={{ width: '100%' }}>
                        <span style={{ color: '#b2b2b2', fontWeight: '600' }}>스포츠 캐쉬</span>
                        <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                          {holdingMoney?.toString().replace(commonReg2, ',')} 원
                        </span>
                      </div>
                      <img src={`${process.env.PUBLIC_URL}/img/header/small_sports.png`} alt="" />
                    </ContentRowSmall>
                    <ContentRowSmall>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          textAlign: 'left',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '7px',
                          }}
                        >
                          <span style={{ color: '#b2b2b2', fontWeight: '600' }}>카지노 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>

                        <CasinoMoneyWithdrawBox onClick={onClickCasinoMoneyWithdrawHandler}>
                          <CustomHiOutlineRefresh />
                          <span style={{ color: `${HermesMainBorder}` }}>회수</span>
                        </CasinoMoneyWithdrawBox>
                      </div>
                      <img src={`${process.env.PUBLIC_URL}/img/header/small_casino.png`} alt="" />
                    </ContentRowSmall>
                  </ContentRowWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('MONEYDEPOSIT')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-deposit.png`} alt="" />
                      <span>입금</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('MONEYWITHDRAWAL')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-withdrawal.png`} alt="" />
                      <span>출금</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('POINTEXCHANGE')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-exchange.png`} alt="" />
                      <span>포인트 전환</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('COUPONUSE')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-coupon.png`} alt="" />
                      <span>쿠폰사용</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('NOTE')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-note.png`} alt="" />
                      <span>쪽지</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('ONETOONE')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-onetoone.png`} alt="" />
                      <span>문의</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <ContentRowSecondWrap
                    onClick={e => {
                      onClickMenuModalHandler('BETTING')
                    }}
                  >
                    <LeftContent>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-deposit.png`} alt="" />
                      <span>배팅내역</span>
                    </LeftContent>
                    <FaChevronRight />
                  </ContentRowSecondWrap>
                  <SignUpButton onClick={onClickSignOutHandler}>로그아웃</SignUpButton>
                </ContentWrap>
              </MobileTopWrap>
            </SignUpModalContentWrap>
          </SignUpModalInner>
        </SignUpModalWrapper>
      </Portal> */}
    </>
  )
}

export default MobileMyInfo

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  min-width: max-content;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  gap: 5px;
  &:hover {
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      background: ${HermesMainText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  span {
    height: 100%;
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainBorder} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 17px;
  height: auto;
  cursor: pointer;
  color: ${HermesMainBorder} !important;
`

const SignUpButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: none;
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 400;
  border: 1px solid ${HermesMainBorder};
`

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 10px;
`
const LeftContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
  }
`
const ContentRowWrap = styled.div`
  display: flex;
  width: 100%;
  background: none;
  gap: 15px;
  height: 62px;
  margin-top: 15px;
`

const ContentRowSecondWrap = styled.div`
  display: flex;
  background: rgb(31, 33, 36);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 15px;
  padding: 0px 20px;
  img {
    width: 30px;
    height: 30px;
  }
  span {
    font-size: 12px;
    color: #fff;
  }
  svg {
    width: 15px;
    height: 15px;
    color: #fff;
  }
`
const ContentRowSmall = styled.div`
  width: 50%;
  background: rgb(31, 33, 36);
  color: #fff;
  display: flex;
  position: relative;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100vw;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

const LevelLogo = styled.img`
  width: 50px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  // background: #16171a !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  font-weight: 300;
`
