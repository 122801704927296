import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { siginIn } from '../../api/authManage/authManageApi'
import { ButtonH40 } from '../../shared/components/GoldButton'
import Portal from '../../shared/components/Portal'
import SilverButtonH40 from '../../shared/components/SilverButton'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { createAccessToken, handleSignIn } from '../../socketio/userAuthSocketIOHandler'
import { setUserInfo } from '../../redux/memberInfoSlice'
import { setSportsBettingInfos } from '../../redux/sportsBettingSlice'
import { setAccessToken } from '../../utils/token'
import CustomLoading from '../../containers/CustomLoading'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */

const MobileSignInPopup = ({ onClose, visible, onShowSignUpModal, isAuth = false }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [])

  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')

  const [inputCaptchaCode, setInputCaptchaCode] = useState('')

  useEffect(() => {
    loadCaptchaEnginge(4, 'black', 'white', 'numbers')
  }, [])

  const [siginInFlag, setSignInFlag] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })

    setUserAgent(window.navigator.userAgent)
  }, [])

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const resetData = () => {
    setMemberId('')
    setMemberPassword('')
    setInputCaptchaCode('')
    loadCaptchaEnginge(4, 'black', 'white', 'numbers')
  }

  const onSubmit = e => {
    if (!memberId) {
      alert('아이디를 입력하세요.')
      resetData()
      return
    }
    if (!memberPassword) {
      alert('비밀번호를 입력하세요.')
      resetData()
      return
    }
    if (!inputCaptchaCode) {
      alert('인증코드를 입력하세요.')
      resetData()
      return
    }

    if (!validateCaptcha(inputCaptchaCode)) {
      alert('인증코드를 잘못 입력하였습니다. 다시 시도해주세요.')
      resetData()
      return
    }

    if (siginInFlag) {
      return
    }

    setSignInFlag(true)

    const body = {
      userId: memberId,
      password: memberPassword,
      // eslint-disable-next-line no-useless-escape
      host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
    }

    handleSignIn(body).then(signInRes => {
      if (!signInRes.status) {
        alert('아이디, 비밀번호 확인 후 다시 시도해주세요.')
        setSignInFlag(false)
        return
      }
      const tokenData = {
        userId: memberId,
        password: memberPassword,
      }

      // 토큰 발급
      createAccessToken(tokenData)
        .then(atRes => {
          if (!atRes.status) {
            alert('다시 로그인해주세요.')
            setSignInFlag(false)
            onClose()
            return
          }
          // 유저 정보 리덕스에 저장
          dispatch(setUserInfo(atRes.data.user))

          dispatch(setSportsBettingInfos(atRes.data.user.sportsBettingInfos))

          // 로컬 스토리지에 토큰 저장
          setAccessToken(atRes.data.accessToken)
          setSignInFlag(false)
          onClose()
        })
        .finally(() => {
          setSignInFlag(false)
        })
    })
  }

  useEffect(() => {
    if (visible) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [visible])
  return (
    <>
      {siginInFlag && <CustomLoading />}
      <Portal elementId="signin-modal">
        <SignInModalOverlay visible={visible} />
        <SignInModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
          <SignInModalInner tabIndex="0">
            <SignInModalHeader>
              <SignInModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: '400',
                  color: 'rgba(255, 255, 255, 0.5)',
                  textAlign: 'left',
                }}
              >
                로그인
              </div>
            </SignInModalHeader>

            <SignInModalContentWrap>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  memberId,
                  memberPassword,
                  inputCaptchaCode,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <SignInModalFormWrap onSubmit={handleSubmit}>
                    <CustomInputWrap style={{ marginBottom: '20px', marginTop: '10px' }}>
                      <CustomInputTitle>아이디</CustomInputTitle>
                      <SignInModalInputWrap>
                        <Field name="memberId">
                          {({ input, meta }) => (
                            <SignInModalInput
                              {...input}
                              type="text"
                              autoComplete="off"
                              value={memberId}
                              style={{ touchAction: 'none' }}
                              placeholder="아이디"
                              onChange={e => {
                                setMemberId(e.target.value)
                              }}
                            />
                          )}
                        </Field>
                      </SignInModalInputWrap>
                    </CustomInputWrap>
                    <CustomInputWrap>
                      <CustomInputTitle>비밀번호</CustomInputTitle>
                      <SignInModalInputWrap>
                        <Field name="memberPassword">
                          {({ input, meta }) => (
                            <SignInModalInput
                              {...input}
                              type="password"
                              inputMode="latin"
                              style={{ touchAction: 'none' }}
                              value={memberPassword}
                              placeholder="패스워드"
                              onChange={e => {
                                setMemberPassword(e.target.value)
                              }}
                            />
                          )}
                        </Field>
                      </SignInModalInputWrap>
                    </CustomInputWrap>

                    <SignInModalInputWrap>
                      <LoadCanvasTemplateNoReload />
                      <SignInModalInputLine />
                      <Field name="inputCaptchaCode">
                        {({ input, meta }) => (
                          <SignInModalInput
                            {...input}
                            type="text"
                            value={inputCaptchaCode}
                            placeholder="인증코드"
                            onChange={e => {
                              setInputCaptchaCode(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </SignInModalInputWrap>
                    <SignInModalButton type="submit">
                      <SignInModalButtonInfo>로그인</SignInModalButtonInfo>
                    </SignInModalButton>
                    <SignUpButton
                      type="button"
                      onClick={e => {
                        onShowSignUpModal()
                        close(e)
                      }}
                    >
                      <SignUpButtonInfo>회원가입</SignUpButtonInfo>
                    </SignUpButton>
                  </SignInModalFormWrap>
                )}
              </Form>
            </SignInModalContentWrap>
          </SignInModalInner>
        </SignInModalWrapper>
      </Portal>
    </>
  )
}

export default MobileSignInPopup

const SignInModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 95%;
  transform: translate(-50%, -50%);
  top: 45vh;
  left: 50%;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const SignInModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

const SignInModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;

  width: 100%;
  margin: 0 auto;

  height: 490px;
`

const SignInModalHeader = styled.div`
  height: 40px;
  background: #16171a !important;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-top: 15px;
  padding-left: 28px;
`

// 로고
const SignInModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
const SignInModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  top: 20px;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignInModalContentWrap = styled.div`
  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  height: calc(100% - 60px);
  background: #16171a !important;
  padding: 0px;
`

// 이용 권한이 없습니다.
const SignInModalContentInfo1 = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;

  /* 엘로우폰트 */

  color: #ffc01a;
`

// 아직 계정이 없으신가요?
const SignInModalContentInfo2 = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;

  color: #d3d3d3;
`

// form
const SignInModalFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 85%;
  //   height: 100%;
`

// input Wrap
const CustomInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const CustomInputTitle = styled.div`
  font-size: 14px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
`

const SignInModalInputWrap = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 5px 10px;
  gap: 10px;

  height: 42px;

  /* 회색테두리 */

  border: 1px solid #4a4a4a;
`

// input icon
const SignInModalInputIcon = styled.img`
  width: 20px;
  height: 20px;
`

// input line
const SignInModalInputLine = styled.div`
  width: 1px;
  height: 22px;

  /* 회색테두리 */

  background: #4a4a4a;
`

// input
const SignInModalInput = styled.input`
  width: 100%;
  // height: 20px;

  padding: 0 10px;

  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;
`

// 버튼 안에 텍스트
const SignInModalButtonInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  text-align: center;

  color: #000;
`

// 로그인 버튼
const SignInModalButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 40px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`

// 회원 가입
const SignUpButtonInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  text-align: center;

  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SignUpButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 0;
  background: none;
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  border: 1px solid ${HermesMainBorder};
`
