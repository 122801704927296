import React, { Fragment } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import styled from 'styled-components'
import { createBettingCartItem } from '../../../shared/components/SportCommonBetBox'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { DomesticBetBox } from '../../../shared/components/SportDomesticBetBox'
import MobileSportsLiveContentMarket from './MobileSportsLiveContentMarket'
import { MoblieDomesticBetBox } from '../../../shared/components/MobileSportDomesticBetBox'
import { MobileSportCommonBetBox } from '../../../shared/components/MobileSportCommonBetBox'

function formatTime(seconds) {
  if (seconds === '-1') return '00:00'

  const minutes = Math.floor(seconds / 60) // 전체 분을 계산
  const remainingSeconds = seconds % 60 // 남은 초를 계산

  // 두 자리 수를 유지하기 위해 문자열 포맷팅
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

const SportEventInProgressFixtureItem = React.memo(
  ({ fixtureKey, fixtureData, onClickSportFixtureMarketAdditionalOptionHandler, onClickAddBettingCartHandler }) => {
    return (
      <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
        <InProgressFixtureItemWrap
          active={fixtureData.additionalOption}
          // onClick={() => {
          //   onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
          // }}
        >
          <InProgressFixtureItemHeaderBox>
            <InProgressFixtureItemHeaderPeriodBox>
              <InProgressFixtureItemHeaderPeriodTextBox>
                <div style={{ float: 'left' }}> 시간: {formatTime(fixtureData?.liveScore?.time)}</div>
                <div style={{ float: 'left' }}>
                  &nbsp;상태:{' '}
                  <span style={{ color: `${HermesMainHover2}` }}>{fixtureData?.liveScore?.currentPeriodKr}</span>
                </div>
              </InProgressFixtureItemHeaderPeriodTextBox>
            </InProgressFixtureItemHeaderPeriodBox>

            <InProgressFixtureItemHeaderTopOptionBox>
              <InProgressFixtureItemHeaderTopOption
                active={fixtureData.additionalOption}
                onClick={() => {
                  onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                }}
              >
                <span>추가 배팅옵션</span>
              </InProgressFixtureItemHeaderTopOption>
            </InProgressFixtureItemHeaderTopOptionBox>
          </InProgressFixtureItemHeaderBox>
          <InProgressFixtureItemBox>
            <InProgressFixtureItemInnerBox>
              <InProgressFixtureItemInnerHomeBox>
                <InProgressFixtureItemInnerHomeTeamBox>
                  {fixtureData.fixture.homeImageUrl ? (
                    <img src={fixtureData.fixture.homeImageUrl} alt="" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
                  )}
                  <div>{fixtureData.fixture.homeName}</div>
                </InProgressFixtureItemInnerHomeTeamBox>
                <InProgressFixtureItemInnerScoreBox style={{ float: 'right' }}>
                  {fixtureData.liveScore?.homeScore || 0}
                </InProgressFixtureItemInnerScoreBox>
              </InProgressFixtureItemInnerHomeBox>
              <InProgressFixtureItemInnerVSBox>VS</InProgressFixtureItemInnerVSBox>
              <InProgressFixtureItemInnerAwayBox>
                <InProgressFixtureItemInnerScoreBox style={{ float: 'left' }}>
                  {fixtureData.liveScore?.awayScore || 0}
                </InProgressFixtureItemInnerScoreBox>
                <InProgressFixtureItemInnerAwayTeamBox>
                  {fixtureData.fixture.awayImageUrl ? (
                    <img src={fixtureData.fixture.awayImageUrl} alt="" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
                  )}
                  <div>{fixtureData.fixture.awayName}</div>
                </InProgressFixtureItemInnerAwayTeamBox>
              </InProgressFixtureItemInnerAwayBox>
            </InProgressFixtureItemInnerBox>

            {fixtureData.mainMarkets ? (
              <>
                {fixtureData.mainMarkets.map(mainMarket => {
                  if (!mainMarket.usedMarket || !mainMarket.marketVisible) return null

                  const filterBetLines = mainMarket.betLines.filter(betLine => {
                    // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                    // const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                    // if (allSuspended) return false

                    // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                    return betLine.bets.every(bet => {
                      // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                      return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                    })
                  })

                  if (filterBetLines.length === 0) return null

                  const customMarket = {
                    ...mainMarket,
                    betLines: filterBetLines,
                  }

                  return (
                    <>
                      <MobileSportCommonBetBox
                        fixtureInfo={{
                          fixtureId: fixtureData.fixtureId,
                          ...fixtureData.fixture,
                        }}
                        marketInfo={customMarket}
                        onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                      />
                    </>
                  )
                })}
              </>
            ) : (
              <div className="show-type">
                <div className="t-line">
                  <li className="date" style={{ width: '15%' }}>
                    <b style={{ letterSpacing: '-1px' }}>-</b>
                  </li>
                  <li
                    className="t-name"
                    style={{ width: '29%' }}
                    onClick={() => {
                      onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                    }}
                  >
                    <div className="odds">
                      <b>{fixtureData.fixture.homeName}</b>
                    </div>
                  </li>
                  <li className="t-name-center" style={{ width: '20%' }}>
                    <div className="odds">
                      <b>VS</b>
                    </div>
                  </li>
                  <li
                    className="t-name"
                    style={{ width: '29%' }}
                    onClick={() => {
                      onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                    }}
                  >
                    <div className="odds">
                      <b>{fixtureData.fixture.awayName}</b>
                    </div>
                  </li>
                </div>
              </div>
            )}
          </InProgressFixtureItemBox>
        </InProgressFixtureItemWrap>

        {fixtureData.additionalOption && (
          <MobileSportsLiveContentMarket
            selectedFixtureInfo={{
              eventKey: fixtureKey,
              fixtureId: fixtureData.fixtureId,
            }}
          />
        )}
      </Fragment>
    )
  },
)

const InProgressFixtureItemWrap = styled.div`
  display: block;
  background: linear-gradient(#fff, #f5fcff);
  margin-top: 20px;
  margin-bottom: 3px;
  position: relative;
  cursor: pointer !important;
  border: 1px solid #eee;

  &:hover {
    cursor: pointer;
    border: 1px solid #000;
  }

  ${props =>
    props.active &&
    `
    cursor: pointer;
    border: 1px solid #000;
    `}
`
const InProgressFixtureItemHeaderBox = styled.div`
  border-radius: 3px;
  width: 100%;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 5;
  text-align: left;
  color: #000;
  padding: 0;
  font-size: 11px;
`

const InProgressFixtureItemHeaderPeriodBox = styled.div`
  display: block;
  margin-left: 1px;
  padding: 2px 12px;
  font-size: 12px;
  float: left;
  color: #000;
  border: 1px solid #000;
  border-radius: 3px;
  background: linear-gradient(#f5fcff, #f5fcff);
  text-align: center;
`

const InProgressFixtureItemHeaderPeriodTextBox = styled.div`
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
`

const InProgressFixtureItemHeaderTopOptionBox = styled.div`
  margin-right: 10px;
  padding: 0;
  float: right;
  border: 1px solid #000;
  border-radius: 3px;
  background: linear-gradient(#f5fcff, #f5fcff);
  text-align: center;
  color: #000;
`

const InProgressFixtureItemHeaderTopOption = styled.div`
  border-radius: 3px;
  float: right;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  color: #000;

  span {
    width: 100%;
    display: block;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #000;
    background: #000;
    color: #fff;
  }

  ${props =>
    props.active &&
    `
    cursor: pointer;
    border: 1px solid #000;
    background: #000;
    color: #fff;
    `}
`

const InProgressFixtureItemBox = styled.div`
  min-height: 92px;
  padding: 2px;
  padding-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InProgressFixtureItemInnerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  font-size: 20px;
  text-align: center;
  color: #000;
  padding-top: 10px;
  padding-bottom: 9px;

  margin-bottom: 2px;
`
const InProgressFixtureItemInnerHomeBox = styled.div`
  width: 45%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerHomeTeamBox = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;

  img {
    width: auto;
    height: 25px;
    margin-bottom: 2px;
  }
`

const InProgressFixtureItemInnerVSBox = styled.div`
  width: 10%;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerAwayBox = styled.div`
  width: 45%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerAwayTeamBox = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;

  img {
    width: auto;
    height: 25px;
    margin-bottom: 2px;
  }
`

const InProgressFixtureItemInnerScoreBox = styled.div`
  display: block;
  padding: 0;
  height: 40px;
  width: 40px;
  border: 1px solid #000;
  line-height: 35px;
`

const FixtureMarketBetBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const FixtureMarketBetBox = styled.div`
  width: ${props => props.wd || 'calc(50% - 39px);'};
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  border-color: #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: linear-gradient(#fff, #f5fcff);
  color: #000;

  ${props =>
    !props.empty &&
    `
        &:hover {
            cursor: pointer;
            color: #3c4043;
            background: linear-gradient(#f5fcff, #f5fcff);
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            border-right: 1px solid #000;
        }  
    `}

  ${props =>
    props.isActive &&
    `
    border: 1px solid #000;
    background: #000;
    color: #fff;
    `}
`

const FixtureMarketBetBoxPrice = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
      padding-right: 10px;
      img {
        margin-right: 5px;
      }
    `
      : `
      padding-left: 10px;
      img {
        margin-left: 5px;
      }
    `}
`

const FixtureMarketBetLineBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.fd || 'column'};
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
      justify-content: start;
      padding-left: 10px;
      `
      : `
      justify-content: end;
      padding-right: 10px;
      `}
`
const SportsEventContentItemPoint = styled.div`
  width: 100px;
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-color: #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: linear-gradient(#fff, #f5fcff);
  color: #000;

  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: #3c4043;
    background: linear-gradient(#f5fcff, #f5fcff);
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  ${props =>
    props.isActive &&
    `
    border: 1px solid #000;
    background: #000;
    color: #fff;
    `}
`
export const MobileSportEventInProgressFixtures = React.memo(
  ({
    eventKey,
    event,
    onClickFixtureCollapseHandler,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
  }) => {
    return (
      <>
        <div>
          <div style={{ position: 'relative' }}>
            <SportFixtureLeagueTitleWrap onClick={() => onClickFixtureCollapseHandler(eventKey)}>
              <SportFixtureLeagueTitleBox>
                <SportsEventTitleTypeImg
                  src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
                  alt=""
                />
                {/* {event.locationImageUrl && <SportsEventTitleCountryImg src={event.locationImageUrl} alt="" />} */}
                <SportsEventTitleCountryInfo>{event.locationName}</SportsEventTitleCountryInfo>
                <SportsEventTitleArrowRightIcon />
                {/* {event.leagueImageUrl && <SportsEventTitleLeagueImg src={event.leagueImageUrl} alt="" />} */}
                <SportsEventTitleLeagueInfo>{event.leagueName}</SportsEventTitleLeagueInfo>
                {/* <SportsEventTitleLeagueCount>{event.fixtures.length}경기</SportsEventTitleLeagueCount> */}
                {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
              </SportFixtureLeagueTitleBox>
            </SportFixtureLeagueTitleWrap>
          </div>
        </div>
        <SportsEventWrap key={eventKey}>
          {event.fixtureLeagueCollapse && (
            <SportsEventContentBox>
              {event.fixtures.map(fixtureData => {
                return (
                  <SportEventInProgressFixtureItem
                    key={`${eventKey}-${fixtureData.fixtureId}`}
                    fixtureKey={eventKey}
                    fixtureData={fixtureData}
                    onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                    onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                  />
                )
              })}
            </SportsEventContentBox>
          )}
        </SportsEventWrap>
      </>
    )
  },
)

const SportFixtureLeagueTitleWrap = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  background: #0062df;
  height: 36px;
  color: #000;
  border-radius: 6px 6px 0 0;
`

const SportFixtureLeagueTitleBox = styled.div`
  font-weight: 500;
  font-size: 12px;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleTypeImg = styled.img`
  width: auto;
  height: 20px;
`
const SportsEventTitleCountryImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  min-width: max-content;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: #000;
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: #000;
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto !important;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: #000;
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto !important;
`

const SportsEventContentBox = styled.div`
  // width: 100%;
  min-height: 50px;
  color: #000;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #eee;
  border-radius: 0px 0px 6px 6px;
  gap: 2px;
`
